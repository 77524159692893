// import LandingPage from '../pages/LandingPage';
// import Resources from '../pages/Resources';
// import ResourceBookingPreview from '../pages/ResourceBookingPreview';
// import ConfirmResourcePreview from '../pages/ConfirmResourcePreview';
// import ResourcesDetails from '../pages/ResourcesDetails';
// import GeneralBookingPage from '../pages/GeneralBookingPage';
// import ManageBookingPage from '../pages/ManageBookingPage';
// import EditBookingPage from '../pages/EditBookingPage';
// import Rooms from '../pages/Rooms';
// import RoomDetails from '../pages/RoomDetails';
// import FindBookings from '../pages/FindBookings';
// import Events from '../pages/Events';
// import EventDetails from '../pages/EventDetails';
// import MeetingRooms from '../pages/MeetingRooms';
// import MeetingRoomDetails from '../pages/MeetingRoomDetails';
// import NotFoundPage from '../pages/NotFoundPage';
// import LogInPage from '../pages/LogInPage';
// import MaintenancePage from '../pages/MaintenancePage';
// import PersonalAreaPage from '../pages/PersonalAreaPage';
// import YourBookingsPage from '../pages/YourBookingsPage';
// import BookingDetailsPage from '../pages/BookingDetailsPage';
// import LoginEntryPage from '../pages/LoginEntryPage';

import loadable from '@loadable/component';
import QRAction from '../pages/QRAction';

const LandingPage = loadable(() => import('../pages/LandingPage'));
const Resources = loadable(() => import('../pages/Resources'));
const ResourceBookingPreview = loadable(() => import('../pages/ResourceBookingPreview'));
const ResourcesDetails = loadable(() => import('../pages/ResourcesDetails'));
const GeneralBookingPage = loadable(() => import('../pages/GeneralBookingPage'));
const ManageBookingPage = loadable(() => import('../pages/ManageBookingPage'));
const EditBookingPage = loadable(() => import('../pages/EditBookingPage'));
const Rooms = loadable(() => import('../pages/Rooms'));
const RoomDetails = loadable(() => import('../pages/RoomDetails'));
const FindBookings = loadable(() => import('../pages/FindBookings'));
const Events = loadable(() => import('../pages/Events'));
const EventDetails = loadable(() => import('../pages/EventDetails'));
const MeetingRooms = loadable(() => import('../pages/MeetingRooms'));
const MeetingRoomDetails = loadable(() => import('../pages/MeetingRoomDetails'));
const NotFoundPage = loadable(() => import('../pages/NotFoundPage'));
const LogInPage = loadable(() => import('../pages/LogInPage'));
const MaintenancePage = loadable(() => import('../pages/MaintenancePage'));
const PersonalAreaPage = loadable(() => import('../pages/PersonalAreaPage'));
const YourBookingsPage = loadable(() => import('../pages/YourBookingsPage'));
const BookingDetailsPage = loadable(() => import('../pages/BookingDetailsPage'));
const LoginEntryPage = loadable(() => import('../pages/LoginEntryPage'));

const routes = [
  {
    path: '/',
    label: 'Select Meeting Place',
    component: LandingPage,
    exact: true,
    topMenu: false,
    isPublicRoute: true,
    redirectTo: ':languageCountry/',
    // isPublicRoute: false,
    id: 1000,
    parent: 0,
    pageBG: "primary",
    route: ''
  },
  {
    path: ':languageCountry/',
    label: 'Select Meeting Place',
    component: LandingPage,
    exact: true,
    topMenu: false,
    isPublicRoute: true,
    // isPublicRoute: false,
    id: 1001,
    parent: 0,
    pageBG: "primary",
    route: ''
  },
  {
    path: ':languageCountry/:meetingPlace/:key/book',
    key: "gbp",
    label: 'General Booking Page',
    component: GeneralBookingPage,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 3000,
    parent: 0,
    pageBG: "primary",
    route: '/book',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/:meetingPlace/:type/:key/book/resources',
    key: "br",
    label: 'Resources',
    component: Resources,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 4000,
    parent: 0,
    pageBG: "primary",
    route: '/book/resources',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/:meetingPlace/:type/:key/book/resources/:id',
    key: "brd",
    label: 'Resource Details',
    component: ResourcesDetails,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 5004,
    parent: 4000,
    pageBG: "alternate",
    route: '/book/resources',
    mobileBG: "primary",
    mobilePadding: 0
  },
  {
    path: ':languageCountry/:meetingPlace/:type/:key/book/resourcestatus/:id',
    key: "brp",
    label: 'Resource Booking Status',
    component: ResourceBookingPreview,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 5006,
    parent: 4000,
    pageBG: "alternate",
    route: '/book/resourcestatus',
    mobileBG: "primary",
    mobilePadding: 0
  },
  {
    path: ':languageCountry/:key/managebooking/:reservation_id/:email',
    key: "mb",
    label: 'Manage Booking Page',
    component: ManageBookingPage,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 6000,
    parent: 0,
    pageBG: "alternate",
    route: '/managebooking',
    mobileBG: "alternate",
    mobilePadding: 0
  },
  {
    path: ':languageCountry/:meetingPlace/:type/:key/editbooking/:reservation_id/:email',
    key: "eb",
    label: 'Edit Booking Page',
    component: EditBookingPage,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 7000,
    parent: 0,
    pageBG: "alternate",
    route: '/editbooking'
  },
  {
    path: ':languageCountry/:meetingPlace/:type/:key/rooms',
    key: "r",
    label: 'Rooms',
    component: Rooms,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 8000,
    parent: 0,
    pageBG: "primary",
    route: '/rooms',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/:meetingPlace/:type/:key/rooms/:id',
    key: "rd",
    label: 'Room Details',
    component: RoomDetails,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 9000,
    parent: 0,
    pageBG: "primary",
    route: '/rooms',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/:meetingPlace/:key/bookings',
    key: "bk",
    label: 'Booking Form',
    component: FindBookings,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 10000,
    parent: 0,
    pageBG: "alternate",
    route: '/bookings'
  },
  {
    path: ':languageCountry/:key/bookings',
    key: "bk",
    label: 'Booking Form',
    component: FindBookings,
    exact: true,
    topMenu: false,
    isPublicRoute: false,
    id: 10000,
    parent: 0,
    pageBG: "alternate",
    route: '/bookings'
  },
  {
    path: ':languageCountry/:meetingPlace/:type/:key/events',
    key: "ev",
    label: 'Events',
    component: Events,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 11000,
    parent: 0,
    pageBG: "primary",
    route: '/events',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/:meetingPlace/:type/:key/events/:id',
    key: "evd",
    label: 'Event Details',
    component: EventDetails,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 12000,
    parent: 0,
    pageBG: "primary",
    route: '/events',
    mobileBG: "primary",
    mobilePadding: 3
  },
  // {
  //   path: ':languageCountry/:meetingPlace/:type/:key/meeting-rooms/:userDetails',
  //   key: "mr",
  //   label: 'Meeting Rooms',
  //   component: MeetingRooms,
  //   exact: true,
  //   topMenu: true,
  //   isPublicRoute: false,
  //   id: 13000,
  //   parent: 0,
  //   pageBG: "primary",
  //   route: '/meeting-rooms',
  //   mobileBG: "primary",
  //   mobilePadding: 3
  // },
  // {
  //   path: ':languageCountry/:meetingPlace/:type/:key/meeting-rooms/:id/:userDetails',
  //   key: "mrd",
  //   label: 'Meeting Room Details',
  //   component: MeetingRoomDetails,
  //   exact: true,
  //   topMenu: true,
  //   isPublicRoute: false,
  //   id: 14000,
  //   parent: 0,
  //   pageBG: "primary",
  //   route: '/meeting-rooms',
  //   mobileBG: "primary",
  //   mobilePadding: 3
  // },
  {
    path: ':languageCountry/:meetingPlace/:type/:key/meeting-rooms',
    key: "mr",
    label: 'Meeting Rooms',
    component: MeetingRooms,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 13000,
    parent: 0,
    pageBG: "primary",
    route: '/meeting-rooms',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/:meetingPlace/:type/:key/meeting-rooms/:id',
    key: "mrd",
    label: 'Meeting Room Details',
    component: MeetingRoomDetails,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 14000,
    parent: 0,
    pageBG: "primary",
    route: '/meeting-rooms',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/404',
    key: "nf",
    label: 'Not Found Page',
    component: NotFoundPage,
    exact: true,
    topMenu: false,
    isPublicRoute: false,
    id: 20000,
    parent: 0,
    pageBG: "primary",
    route: '/404',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: 'login',
    label: 'Login Page',
    component: LogInPage,
    exact: true,
    topMenu: false,
    isPublicRoute: true,
    id: 2000,
    parent: 0,
    pageBG: "primary",
    route: 'login'
  },
  {
    path: ':languageCountry/qr-action',
    key: "qr",
    label: 'QR Action',
    component: QRAction,
    exact: true,
    topMenu: false,
    isPublicRoute: false,
    id: 5000,
    parent: 0,
    pageBG: "primary",
    route: '/qr-action',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/under-maintenance',
    key: "um",
    label: 'Maintenance Page',
    component: MaintenancePage,
    exact: true,
    topMenu: false,
    isPublicRoute: false,
    id: 30000,
    parent: 0,
    pageBG: "primary",
    route: '/under-maintenance',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/:meetingPlace/:key/personal-area',
    key: "pa",
    label: 'Personal Area Page',
    component: PersonalAreaPage,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 40000,
    parent: 0,
    pageBG: "primary",
    route: '/personal-area',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/:meetingPlace/:key/my-bookings',
    key: "mbp",
    label: 'My Bookings Page',
    component: YourBookingsPage,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 41000,
    parent: 0,
    pageBG: "primary",
    route: '/my-bookings',
    mobileBG: "primary",
    mobilePadding: 3
  },
  {
    path: ':languageCountry/:meetingPlace/:key/booking-details/:reservation_id',
    key: "bd",
    label: 'Booking Details Page',
    component: BookingDetailsPage,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 50000,
    parent: 0,
    pageBG: "primary",
    route: '/booking-details',
    mobileBG: "primary",
    mobilePadding: 0
  },
  {
    path: ':languageCountry/:meetingPlace/:key/login-entry',
    key: "le",
    label: 'Login Entry Page',
    component: LoginEntryPage,
    exact: true,
    topMenu: true,
    isPublicRoute: false,
    id: 60000,
    parent: 0,
    pageBG: "alternate",
    route: '/login-entry',
    mobileBG: "primary",
    mobilePadding: 0
  },
];

export default routes;