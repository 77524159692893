/**
 *
 * @name = Public Routes
 * @description = Public routes are Log in, SignUp, Forgot Password,
 * Reset Password. In simple words, These routes can be accessed
 * before login into the App.
 *
 * @author = Naveen Kumar
 * @since = 07 Oct, 2021
 *
 */

import { Route } from "react-router-dom";
import CustomAlertContainer from "../components/CustomAlertContainer";
import Header from "../components/Header";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";


const PublicRoute = ({ children, ...rest }) => {
  const [height, setHeight] = useState(0)
  const ref = useRef(null)
  const app = useSelector(state => state.app)

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [])

  return (
    <>
      <Route
        {...rest}
        render={({ location }) => (
          <>
            <div
              style={{ height: "100%", overflow: "hidden" }}
            >
              <div ref={ref}><Header topMenu={app?.page?.topMenu || false}></Header></div>
              <div className="page-container" style={{ height: `calc(100vh - (${height}px))` }}>{children}</div>
            </div>
            <CustomAlertContainer></CustomAlertContainer>
          </>
        )}
      />
      {/* <Redirect path="*" to='/404' /> */}
    </>
  );
};

export default PublicRoute;
