
import { booking } from '../../models';
import { bookingTypes } from '../types'

const INITIAL_STATE = { ...booking };

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case bookingTypes.CLEAN_BOOKING_DETAILES:
            return {
                ...state,
                ...booking
            }
        case bookingTypes.CLEAN_RESERVATION_SLOT:
            return {
                ...state,
                reservationSlot: action?.payload || {}
            }
        case bookingTypes.SELECTED_RESOURCE:
            return {
                ...state,
                selectedResource: action?.payload || {}
            }
        case bookingTypes.RESERVATION_SLOT:
            return {
                ...state,
                reservationSlot: {
                    ...state.reservationSlot,
                    [action?.payload?.name]: action?.payload?.value
                }
            }
        case bookingTypes.USER_DETAILS:
            return {
                ...state,
                userDetails: {
                    ...state.userDetails,
                    [action?.payload?.name]: action?.payload?.value
                }
            }
        case bookingTypes.EDIT_USER_DETAILS:
            return {
                ...state,
                userDetails: (() => {
                    return { ...state.userDetails, [action?.payload?.name]: action?.payload?.value };
                })()
            }
        case bookingTypes.CONFIRM_RESERVATION:
            return {
                ...state,
                confirmReservation: {
                    ...state?.confirmReservation,
                    ...action?.payload
                }
            }
        case bookingTypes.EDIT_RESERVATION:
            return {
                ...state,
                confirmReservation: { ...state.confirmReservation, edited: action?.payload }
            }
        case bookingTypes.CANCEL_RESERVATION:
            return {
                ...state,
                confirmReservation: { ...state.confirmReservation, cancelled: action?.payload }
            }
        case bookingTypes.BOOKINGS:
            return {
                ...state,
                bookings: action?.payload
            }
        case bookingTypes.IS_DYNAMIC_BOOKING:
            return {
                ...state,
                isDynamicWeekly: action?.payload
            }
        case bookingTypes.RESOURCE_TYPE:
            return {
                ...state,
                resourceType: action?.payload
            }
        case bookingTypes.BOOKED_DATES:
            return {
                ...state,
                bookedDates: action?.payload
            }
        case bookingTypes.ERROR:
            return {
                ...state,
                error: action?.payload
            }
        case bookingTypes.B2B_USER_DETAILS:
            return {
                ...state,
                b2bUserDetails: action?.payload
            }
        case bookingTypes.EMAIL_HASH:
            return {
                ...state,
                emailHash: action?.payload
            }
        case bookingTypes?.USER_TYPE:
            return {
                ...state,
                userDetails: { ...state?.userDetails, userType: action?.payload }
            }
        //To restore the reservation details after user lands on the Personal details page from ICIM login process
        case bookingTypes?.RESTORE_BOOKING:
            return {
                ...state,
                reservationSlot: { ...action?.payload }
            }
        case bookingTypes?.SET_ACTIVE_BOOKINGS:
            return {
                ...state,
                activeBookings: [...action?.payload]
            }
        case bookingTypes?.SET_OLD_BOOKINGS:
            return {
                ...state,
                oldBookings: [...action?.payload]
            }
        case bookingTypes?.SET_SELECTED_BOOKING:
            return {
                ...state,
                selectedBooking: action?.payload
            }
        case bookingTypes?.MARKETING_UPDATES:
            return {
                ...state,
                userDetails: {
                    ...state?.userDetails,
                    marketingUpdates: action?.payload
                }
            }
        case bookingTypes.EVENT_TYPE:
            return {
                ...state,
                eventType: action.payload
            }
        case bookingTypes?.POPULATE_BOOKING:
            return action?.payload
        case bookingTypes?.BOOKING_REFUND_PERIOD: {
            return {
                ...state,
                confirmReservation: {
                    ...state.confirmReservation,
                    refundPeriod: action?.payload
                }
            }
        }
        case bookingTypes?.RESERVATION_QR_CODE: {
            return {
                ...state,
                confirmReservation: {
                    ...state.confirmReservation,
                    reservation_qr_code: action?.payload
                }
            }
        }
        case bookingTypes?.CLEAN_CONFIRM_RESERVATION: {
            return {
                ...state,
                confirmReservation: booking?.confirmReservation
            }
        }
        default: return state;
    }
}
export default reducer;