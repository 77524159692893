/***
 *
 * Controller class for user.
 * @file CustomAlert.js
 * @description CustomAlert component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import './CustomAlert.scss';
// eslint-disable-next-line no-unused-vars
import { useDispatch, useSelector } from 'react-redux';
import { showHideAlert } from "../../redux/actions";
import StyledButton from '../StyledButton';


const CustomAlert = (props=null) => {
  const app = useSelector(state => state?.app);
  const alert = props || app?.alert;
  const dispatch = useDispatch();

  const hideAlert = () => {
    dispatch(showHideAlert("", ""));
  }

  return (
    <>
      {alert?.title && alert?.message && 
        <div data-testid="CustomAlert" className={"masterPopup"}>
          <div className={"masterEditPopup"}>
            <div className={"editPopupContent"}>
              <div className={"editPopupRow"}>
                <label className={"alertTitle"}>{alert?.title}</label>
                <label className={"alertMessage"}>{alert?.message}</label>
              </div>
              {!alert?.hideButton && 
                <div className={"alertOkayBtn"}>
                  <StyledButton class="black" onClick={() => { hideAlert() }}>Okay</StyledButton>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
};

CustomAlert.propTypes = {};

CustomAlert.defaultProps = {};

export default CustomAlert;
