/***
 *
 * Controller class for user.
 * @file CustomAlertContainer.js
 * @description CustomAlertContainer component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import './CustomAlertContainer.scss';
import { useSelector } from 'react-redux';
import ProgressBarPopup from '../ProgressBarPopup';
import ProgressBar from '../ProgressBar';
import CustomAlert from '../CustomAlert';


const CustomAlertContainer = () => {
  const app = useSelector(state => state.app);

    return (
      <div data-testid="CustomAlertContainer">
        <ProgressBarPopup open={app?.showProgressBar} noClose={true}>
          <ProgressBar></ProgressBar>
        </ProgressBarPopup>
        <CustomAlert></CustomAlert>
      </div>
    )
};

CustomAlertContainer.propTypes = {};

CustomAlertContainer.defaultProps = {};

export default CustomAlertContainer;
