import { sessionTypes } from '../types';
import { SaveSession, RemoveSession } from '../../helpers'
import { AppActions } from '../actions/app.actions';
import { appDefaults } from '../../config';
import { app } from '../../models';
import { fetchMeetingPlaces } from '../../services/json.service';


const INITIAL_STATE = { ...app };

const reducer = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case sessionTypes?.MEETING_PLACE:
            SaveSession(sessionTypes?.MEETING_PLACE, JSON.stringify(action?.payload));
            return {
                ...state,
                meetingPlace: action?.payload

            };
        case sessionTypes?.COUNTRY:
            SaveSession(sessionTypes?.COUNTRY, JSON.stringify(action?.payload));
            return {
                ...state,
                country: action?.payload

            };
        case sessionTypes?.SITE:
            SaveSession(sessionTypes?.SITE, JSON.stringify(action?.payload));
            return {
                ...state,
                site: action?.payload
            };
        case sessionTypes?.CLEAN_SITE_DETAILS:
            RemoveSession("site");
            return {
                ...state,
                site: {}
            };
        case sessionTypes?.CLEAN_PAGE_DETAILS:
            return {
                ...state,
                page: {}
            };
        case sessionTypes?.CLEAN_MEETING_PLACE_DETAILS:
            return {
                ...state,
                meetingPlace: {}
            };
        case sessionTypes?.LANGUAGE:
            SaveSession(sessionTypes?.LANGUAGE, action?.payload);
            return {
                ...state,
                selectedLanguage: action?.payload || appDefaults?.language
            };
        case sessionTypes?.CURRENT_PAGE:
            return {
                ...state,
                page: action?.data
            };
        case sessionTypes?.APPLOADER:
            return {
                ...state,
                appLoader: action?.payload
            }
        case sessionTypes?.APILOADER:
            return {
                ...state,
                apiLoader: action?.payload
            }
        case sessionTypes?.FUNLOADER:
            return {
                ...state,
                funLoader: action?.payload
            }
        case sessionTypes?.PAGELOADER: {
            return {
                ...state,
                pageLoader: action?.payload
            }
        }
        case sessionTypes?.APP_ERROR: {
            return {
                ...state,
                error: action?.payload
            }
        }

        case AppActions?.SHOW_PB: {
            return {
                ...state,
                showProgressBar: true
            };
        }
        case AppActions?.HIDE_PB: {
            return {
                ...state,
                showProgressBar: false
            };
        }
        case AppActions?.ALERT: {
            return {
                ...state,
                alert: action?.payload
            };
        }
        case sessionTypes?.CONFIGURATION: {
            return {
                ...state,
                config: {
                    ...state?.config,
                    [action?.payload?.name]: action?.payload?.value
                }
            }
        }
        case sessionTypes?.EXIT_BOOKING_FLOW: {
            return {
                ...state,
                exitBooking: action?.payload
            }
        }
        case sessionTypes?.ICIM_ACTION_ERROR: {
            return {
                ...state,
                icimActionError: action?.payload
            }
        }
        case sessionTypes?.PORTAL: {
            return {
                ...state,
                portal: action?.payload
            }
        }
        default: return state;
    }
};
export default reducer;