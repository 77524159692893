import { AppBar, Box, Container, FormControl, IconButton, SvgIcon, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
// import PropTypes from 'prop-types';

import './TopNavigation.scss';
import LanguageSelector from '../LanguageSelector';
import { GetSession, history } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MeetingPlaceDropDownList from '../MeetingPlaceDropDownList';
import SiteLogo from '../SiteLogo';
import { navigationTypes, sessionTypes } from '../../redux/types';
import { ReactComponent as Profile } from '../../assets/svg/profile.svg';
import { handleIcimRedirection } from '../../redux/actions';
import { siteTypes } from '../../constants';

const TopNavigation = ({ topMenu, style }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const app = useSelector(state => state.app);
  const authentication = useSelector(state => state.authentication);
  const isB2bEvent = (useSelector(state => state?.app?.portal) === 'b2b' || GetSession('portal') === 'b2b') ? true : false;
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const maxSize = useMediaQuery(theme.breakpoints.up(1440));


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  /**
   * @function hanldeProfileIcon
   * @description a function for saving current user session(redirection route, selected meeting place and country) and redirecting to the user to the ICIM login page.
   *              If the user is already loggedin then he/she will be redirected to the PersonalArea page
   * @return void
   */
  const hanldeProfileIcon = () => {
    // put a check whether the user is currently going through the booking process or not
    if (app?.page?.id === navigationTypes?.RESOURCEDETAILS) {
      dispatch({ type: sessionTypes?.EXIT_BOOKING_FLOW, payload: true });
      return
    }
    handleIcimRedirection(authentication, dispatch, history, app, location);
  }

  useEffect(() => {
    const selectedLanguage = GetSession("selectedLanguage");
    const cont = GetSession('country');
    i18n.changeLanguage(selectedLanguage?.toLowerCase() + "-" + JSON.parse(cont)?.code);
  }, [app?.selectedLanguage]);

  return (
    <AppBar position="static" className={"TopNavigation"} style={style} data-testid="TopNavigation">
      <Box sx={{
        width: '100%',
        maxWidth: '1440px',
        pl: matches ? 0 : 3,
        pr: 2,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
        <Toolbar disableGutters>
          <Box display={'flex'} flexDirection={'column'} sx={{ display: topMenu ? 'block' : 'none' }}>
            <SiteLogo className={"mplogo"} />
            <Typography variant='h6' className={"mpName"} mt={1} display={{ xs: 'block', md: 'none' }}>
              {app?.meetingPlace?.name}
            </Typography>
          </Box>
          <Box ml={5.5} width={'23%'}
            sx={{ display: { xs: 'none', md: topMenu ? 'block' : 'none' } }}>
            <FormControl fullWidth>
              <MeetingPlaceDropDownList />
            </FormControl>
          </Box>

          <Box className="tnProfile" sx={{ display: "flex", flexDirection: "row", marginLeft: 'auto', marginRight: { md: (maxSize ? 0 : 3), xs: "16px" } }}>
            <Box sx={{ display: { md: "block", xs: !topMenu ? 'block' : 'none' } }}><LanguageSelector topMenu={topMenu} /></Box>
            {(topMenu &&
              (app?.site?.type !== siteTypes?.MEETINGROOM) &&
              app?.meetingPlace?.url &&
              app?.meetingPlace?.ICIMApplicable &&
              (!app?.meetingPlace?.b2bEventApplicable || (app?.meetingPlace?.b2bEventApplicable && !isB2bEvent))) &&
              <Box sx={{ margin: "auto", display: { xs: topMenu ? 'block' : 'none' } }}>
                <IconButton onClick={hanldeProfileIcon} aria-label='Profile'>
                  <SvgIcon component={Profile} title={'Profile'} />
                </IconButton>
              </Box>}
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

TopNavigation.propTypes = {};

TopNavigation.defaultProps = {};

export default TopNavigation;
