/***
 *
 * @file StyledButton.js
 * @description StyledButton component
 * @author Sanchit Rustagi
 * @since 28 Mar 2023
 * 
 */

import { Button } from '@mui/material';
import React from 'react';
// import PropTypes from 'prop-types';
// import styles from './StyledButton.module.scss';

const StyledButton = ({ children, variant, sx, ...rest }) => {

  return (
    <Button
      aria-disabled={variant === 'disabled' ? true : null}
      data-testid="StyledButton"
      sx={sx}
      variant={variant}
      {...rest}>
      {children}
    </Button>
  )
};

StyledButton.propTypes = {};

StyledButton.defaultProps = {};

export default StyledButton;
