/***
 *
 * Controller class for user.
 * @file PageContentHolder.js
 * @description PageContentHolder component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Paper, useMediaQuery, useTheme, Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import './PageContentHolder.scss';

const PageContentHolder = (props) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const currentRoute = useSelector(state => state?.app?.page);

    return (
        <>
            {
                props?.topMenu ?
                    (<div data-testid="PageContentHolder" className={"PageContentHolder"}>
                        <Paper elevation={0} variant={currentRoute?.pageBG} sx={{

                            px: matches ? 13 : currentRoute?.mobilePadding,
                            py: matches ? 10 : 3,
                            margin: 'auto',
                            flexGrow: 1,
                        }}>
                            <Box data-testid="child-container" sx={{
                                maxWidth: '1440px',
                                mx: 'auto'
                            }}>
                                {props.children}

                            </Box>
                        </Paper>
                    </div >) : (<div className="page-container" style={{ height: `calc(100vh - (${props.headerHeight || 144}px))` }}>
                        {props.children}
                    </div>)
            }
        </>

    )
};

PageContentHolder.propTypes = {};

PageContentHolder.defaultProps = {};

export default PageContentHolder;

