/***
 *
 * Controller class for user.
 * @file AccessDenied3.js
 * @description AccessDenied3 component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
import './AccessDenied3.scss';
import denied3 from '../../assets/images/denied3.jpg';
import ImageWrapper from '../../components/ImageWrapper';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

const AccessDenied3 = () => {
  const { t } = useTranslation();
  const descriptionSx = {
    padding: '0 10px 0 10px',
    textAlign: 'center',
    fontSize: '1rem'
  }

  return (
    <Box className={"AccessDenied3"} data-testid="AccessDenied3">
      <Typography variant={'h2'} sx={{ fontSize: '5rem' }}>403</Typography>
      <Typography variant={'subtitle1'} sx={{ fontSize: '2rem' }}>{t("S_ADMIN_NOT_FOUND")}</Typography>
      <Typography variant={'body1'} sx={descriptionSx}>{t("S_CONTACT_FOR_ADMIN_ACCESS")}</Typography>
      <ImageWrapper src={denied3} height={"300px"} width={"450px"} className={"errorImage"} alt='access-denied-logo' />
    </Box>
  )
};

AccessDenied3.propTypes = {};

AccessDenied3.defaultProps = {};

export default AccessDenied3;
