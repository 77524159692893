/***
 *
 * Controller class for user.
 * @file ImageWrapper.js
 * @description ImageWrapper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import './ImageWrapper.scss';
import defaultImage from '../../assets/defaults/img.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
// import 'react-lazy-load-image-component/src/effects/blur.css';
import { isServer } from '../../helpers/server.helper';

const ImageWrapper = (props) => {
  const [hideImage, setHideImage] = useState(false);

  const addDefaultSrc = (en) => {
    en.target.src = defaultImage;
  }

  useState(() => {
    setHideImage((props?.hide || false))
  }, [props?.show, isServer])

  return (
    <>
      {!hideImage &&
        <LazyLoadImage
          effect='blur'
          style={{ ...props?.style }}
          width={props?.width}
          height={props?.height}
          data-testid="ImageWrapper"
          placeholderSrc={props?.defaultImage || defaultImage}
          src={props?.src || (props?.defaultImage || defaultImage)}
          className={props?.className || ""}
          alt={props?.alt || "img"}
          onError={addDefaultSrc}
          onClick={props?.onClick}
          onMouseEnter={props?.onMouseEnter}
          onMouseLeave={props?.onMouseLeave}
        />
      }
    </>
  )
};

ImageWrapper.propTypes = {};

ImageWrapper.defaultProps = {};

export default ImageWrapper;
