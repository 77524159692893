import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './App';
import { history } from './helpers';
import { store } from './redux/store';
// import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { theme } from './themes/main.theme';

import './i18n';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { client } from './config';
import { loadableReady } from '@loadable/component';

// import i18n (needs to be bundled ;))

if (typeof window !== 'undefined') {
    const renderMethod = client ? render : hydrate;
    loadableReady(() => {
        renderMethod(
            <Provider store={store}>
                <CacheProvider value={createCache({ key: 'css' })}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline></CssBaseline>
                        <CookiesProvider>
                            <Router location={history.location} history={history}>
                                <App />
                            </Router>
                        </CookiesProvider>
                    </ThemeProvider>
                </CacheProvider>
            </Provider>,
            document.getElementById('root')
        );
    })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
