import moment from "moment";
import { API, setCookie } from ".";
import { API_URL, API_URL_AUTHENTICATE, backendURL } from "../config";
import mapper from "./mapper.json";

const urlMain = API_URL;

/**
 * @function mapping
 * @description maps planyo end-points with wrapper end-points and creates the API url
 * @param {string} api_url string that denotes the API name 
 * @param {object} request_payload object that contains the payload to be sent with the API call
 * @returns {object} returns the API payload, the http method and the API url 
 */
export function mapping(api_url, request_payload = {}) {
    if (request_payload?.setPropertyFor) {
        api_url = api_url + "_" + request_payload?.setPropertyFor;
    } else if (request_payload?.getPropertyFor) {
        api_url = api_url + "_" + request_payload?.getPropertyFor;
    }
    const api = mapper[api_url];
    let url = "";

    if (request_payload) {
        // for (let key in api["payload"]) {
        //     let val = request_payload[key];
        //     if (request_payload[key]) {
        //         payload[api["payload"][key]] = val;
        //     }
        // }
        for (let key in api["pathParams"]) {
            if (key !== "") {
                url += "/" + key + "/" + request_payload[api["pathParams"][key]];
            }
            else {
                url += "/" + request_payload[api["pathParams"][key]];
            }
        }
    }
    return {
        payload: (() => {
            let payload = {};
            if (request_payload) {
                for (let key in api["payload"]) {
                    let val = request_payload[key];
                    if (request_payload[key]) {
                        payload[api["payload"][key]] = val;
                    }
                }
            }
            return payload;
        })(),
        httpMethod: api?.httpMethod,
        // pathParams: (() => {
        //     let pathParams = {};
        //     if (request_payload) {
        //         for (let key in api["pathParams"]) {
        //             // let val = request_payload[key];
        //             let val = request_payload[api["pathParams"][key]];
        //             if (request_payload[api["pathParams"][key]]) {
        //                 pathParams[api["pathParams"][key]] = val;
        //             }
        //         }
        //     }
        //     return pathParams;
        // })(),
        queryParams: (() => {
            let queryParams = {};
            if (request_payload) {
                for (let key in api["queryParams"]) {
                    let val = request_payload[key];
                    if (request_payload[key]) {
                        queryParams[api["queryParams"][key]] = val;
                    }
                }
            }
            return queryParams;
        })(),
        url: url + (api?.api_url ? ("/" + api?.api_url) : "")
    };
}

/**
 * @function reqUrl
 * @description generates the payload of the API call to be passed in its body
 * @param {string} api_url string that denotes the API name
 * @param {object} param1 contains API payload, http method to be used and the url generated in mapper function
 * @param {object} options contains additional specifications that may be required 
 * @returns {object} returns the complete API url, API key, API payload to be passed in its body and options
 */
export function reqUrl(api_url, { payload, queryParams, httpMethod, url }, options = {}) {
    let httpServer = urlMain;
    // if (api_url === "get_site_info") {
    //     httpServer = urlGetSiteIfo;
    // }

    if (["get", "delete"].includes(httpMethod)) {
        // if (pathParams) {
        //     pathParamFlag = 1;
        //     url += '?';
        //     let delim = "";
        //     for (let key in pathParams) {
        //         url += delim + key + "=" + (pathParams[key] ?? "");
        //         delim = "&";
        //     }
        // }
        if (queryParams) {
            url += '?';
            let delim = "";
            for (let key in queryParams) {
                url += delim + key + "=" + (queryParams[key] ?? "");
                delim = "&";
            }
        }
        return { path: httpServer + url, key: httpServer + url, ...options };
    } else {
        return { path: httpServer + url, key: httpServer + url, body: payload, ...options };
    }
    // if (httpMethod === "get" || httpMethod === "delete") {
    //     if (payload) {
    //         paramsStr = "?";
    //         let delim = "";
    //         for (let key in payload) {
    //             paramsStr = paramsStr + delim + key + "=" + (payload[key] ?? "");
    //             delim = "&";
    //         }
    //     }
    //     return { path: httpServer + url + paramsStr, key: httpServer + url + paramsStr, ...options };
    // } else {
    //     return { path: httpServer + url + paramsStr, key: httpServer + url + paramsStr, body: payload, ...options };
    // }
}

/**
 * @function compareTime
 * @description calculates the time difference between the current time and the expiry time of the cookie
 * @param {object} expiresIn a moment object that stands for the time at which a cookie will expire 
 * @returns {number} a number that denotes the time difference between present time and expiry time of cookie
 */
export function compareTime(expiresIn) {
    let current = moment();
    let timeDiff = moment(expiresIn).diff(current, 'seconds');
    return timeDiff;
}



/**
 * @function reqToken
 * @description fires an API call to acquire an access token and upon receiving, store it in cookies
 */
export async function reqToken() {
    await API.get({
        path: { baseURL: `${backendURL}/apiAuth/services`, path: API_URL_AUTHENTICATE },
        key: "WRAPPER-API-Auth"
    }).then(token => {
        if (token?.data?.access_token) {
            setCookie("accessToken", token?.data?.access_token, token?.data?.expires_in);
        }
    });
}


