/***
 *
 * Controller class for user.
 * @file ProgressBarSVGIcons.js
 * @description ProgressBarSVGIcons component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';

const ProgressBarSVGIcons = {

  'direction-left': (
    <path d="M 5 12 L 13 4 l 2 2 L 8.384 12 l 6.616 6 l -2 2 z" />
  ),
  'direction-right': (
    <path d="M 7.947 18.646 L 14.593 12 L 7.947 5.354 l 2.053 -1.354 L 18 12 l -8 8 z" />
  ),
  'direction-down': (
    <polyline
      id="path-1"
      points="12 16.007 4.647 8.653 5.354 7.946 12 14.593 18.646 7.946 19.354 8.653 12 16.007"
    />
  ),
  'direction-up': (
    <polygon
      id="Mask"
      points="12 8.407 5.354 15.054 4.647 14.347 12 6.993 19.354 14.347 18.647 15.054"
    />
  ),
  greenTick: (
    <svg viewBox="20 0 350 340" width="50px" height="50px">
      <path fill="green" d="M 131 295 C 161 319 173 316 190 299 l 145 -212 a 24 24 0 0 0 -40 -29 l -63 92 L 161 259 l -49.539 -49.539 a 24 24 0 0 0 -33.941 0 a 24 24 0 0 0 0 33.941 z"/>
    </svg>
  ),
  notification: (
    <svg viewBox="0 0 511.156 511.156" >
      <path fill="white" d="m184.904 465.044c11.999 27.127 39.154 46.112 70.674 46.112s58.674-18.985 70.674-46.112z"/>
      <path fill="white" d="m255.573 48.836c20.8 0 40.772 3.67 59.306 10.389v-2.283c0-31.398-25.544-56.942-56.941-56.942h-4.719c-31.398 0-56.942 25.544-56.942 56.942v2.254c18.524-6.699 38.49-10.36 59.296-10.36z"/>
      <path fill="white" d="m442.747 435.044h-374.338c-7.082 0-13.569-4.776-15.042-11.704-1.458-6.859 1.668-13.629 8.01-16.559 1.505-.976 12.833-8.897 24.174-32.862 20.829-44.01 25.201-106.005 25.201-150.263 0-79.855 64.967-144.82 144.821-144.82 79.665 0 144.512 64.652 144.82 144.245.007.191.011.383.011.575 0 44.258 4.372 106.253 25.201 150.263 11.341 23.965 22.668 31.887 24.174 32.862 6.342 2.93 9.469 9.699 8.01 16.559-1.473 6.927-7.959 11.704-15.042 11.704zm7.2-28.157h.01z"/>
    </svg>
  ),
  info: (
    <svg viewBox="0 0 512 512">
      <path d="M256.002,182.774c-12.718,0-21.761,5.37-21.761,13.283V303.73c0,6.782,9.044,13.565,21.761,13.565
        c12.152,0,22.043-6.783,22.043-13.565V196.057C278.045,188.144,268.154,182.774,256.002,182.774z"/>
      <path d="M256.002,116.361c-13,0-23.174,9.326-23.174,20.065c0,10.739,10.174,20.348,23.174,20.348
        c12.718,0,22.892-9.609,22.892-20.348C278.894,125.688,268.719,116.361,256.002,116.361z"/>
      <path d="M256,0C134.398,0,36,98.348,36,219.873c0,99.231,65.333,181.626,153.417,209.609l50.032,73.747
        C243.171,508.715,249.37,512,256,512s12.829-3.285,16.551-8.771l50.032-73.747C410.706,401.486,476,319.061,476,219.873
        C476,98.271,377.523,0,256,0z M304.645,393.099c-4.555,1.274-8.51,4.12-11.165,8.033L256,456.376l-37.479-55.243
        c-2.656-3.913-6.611-6.76-11.166-8.033C132.377,372.135,76,303.181,76,219.873C76,120.69,156.748,40,256,40
        s180,80.69,180,179.873C436,303.264,379.54,372.158,304.645,393.099z"/>
    </svg>
  ),
  search:(
    <svg viewBox="0 0 56.966 56.966">
      <path fill="gray" d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
        s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
        c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
        s-17-7.626-17-17S14.61,6,23.984,6z"/>
    </svg>
  ),
  filter: (
    <svg viewBox="0 0 459 459">
        <path fill="gray" d="M178.5,382.5h102v-51h-102V382.5z M0,76.5v51h459v-51H0z M76.5,255h306v-51h-306V255z"/>
    </svg>
  ),
  close: (
    <svg viewBox="0 0 329.26933 329">
      <path fill="gray" d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
    </svg>
  ),
  profile: (
    <svg fill="white" viewBox="0 0 600 600">
      <defs>
        <clipPath id="circular-border">
          <circle cx="300" cy="300" r="280" />
        </clipPath>
        <clipPath id="avoid-antialiasing-bugs">
        <rect width="100%" height="498" />
        </clipPath>
      </defs>
      <circle cx="300" cy="300" r="280" fill="black" clipPath="url(#avoid-antialiasing-bugs)" />
      <circle cx="300" cy="230" r="115" />
      <circle cx="300" cy="550" r="205" clipPath="url(#circular-border)" />
    </svg>
  ),
  menu: (
    <svg viewBox="0 0 512 512">
      <path fill="white" d="m464.883 64.267h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"/>
      <path fill="white" d="m464.883 208.867h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"/>
      <path fill="white" d="m464.883 353.467h-417.766c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.012-21.137-47.149-47.117-47.149z"/>
    </svg>
  ),
  edit: (
    <svg viewBox="0 0 512 512">
      <polygon fill="gray" points="51.2,353.28 0,512 158.72,460.8"/>
      <rect fill="gray" x="89.73" y="169.097" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -95.8575 260.3719)" width="353.277" height="153.599"/>
      <path fill="gray" d="M504.32,79.36L432.64,7.68c-10.24-10.24-25.6-10.24-35.84,0l-23.04,23.04l107.52,107.52l23.04-23.04 C514.56,104.96,514.56,89.6,504.32,79.36z"/>
    </svg>
  ),
  delete: (
    <svg viewBox="-48 0 407 407">
      <path fill="gray" d="m89.199219 37c0-12.132812 9.46875-21 21.601562-21h88.800781c12.128907 0 21.597657 8.867188 21.597657 21v23h16v-23c0-20.953125-16.644531-37-37.597657-37h-88.800781c-20.953125 0-37.601562 16.046875-37.601562 37v23h16zm0 0"/>
      <path fill="gray" d="m60.601562 407h189.199219c18.242188 0 32.398438-16.046875 32.398438-36v-247h-254v247c0 19.953125 14.15625 36 32.402343 36zm145.597657-244.800781c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm-59 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm-59 0c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v189c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8zm0 0"/>
      <path fill="gray" d="m20 108h270.398438c11.046874 0 20-8.953125 20-20s-8.953126-20-20-20h-270.398438c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20zm0 0"/>
    </svg>
  ),
  home:(
    <svg viewBox="0 0 426.667 426.667">
      <polygon points="213.333,32 0,224 64,224 64,394.667 170.667,394.667 170.667,266.667 256,266.667 256,394.667 362.667,394.667 362.667,224 426.667,224"/>
    </svg>
  ),
  downloadExcel: (
    <svg viewBox="0 0 512 512">
      <path fill="gray" d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z"/>
      <path fill="gray" d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z"/>
    </svg>
  ),
  approve: (
    <svg viewBox="0 0 40 40">
      <path fill="#bae0bd" d="M20,38.5C9.8,38.5,1.5,30.2,1.5,20S9.8,1.5,20,1.5S38.5,9.8,38.5,20S30.2,38.5,20,38.5z"></path>
      <path fill="#5e9c76" d="M20,2c9.9,0,18,8.1,18,18s-8.1,18-18,18S2,29.9,2,20S10.1,2,20,2 M20,1C9.5,1,1,9.5,1,20s8.5,19,19,19	s19-8.5,19-19S30.5,1,20,1L20,1z"></path>
      <path fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3" d="M11.2,20.1l5.8,5.8l13.2-13.2"></path>
    </svg>
  ),
  reject: (
    <svg viewBox="0 0 40 40">
      <path fill="#f78f8f" d="M20,38.5C9.799,38.5,1.5,30.201,1.5,20S9.799,1.5,20,1.5S38.5,9.799,38.5,20S30.201,38.5,20,38.5z"></path>
      <path fill="#c74343" d="M20,2c9.925,0,18,8.075,18,18s-8.075,18-18,18S2,29.925,2,20S10.075,2,20,2 M20,1 C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1L20,1z"></path>
      <path fill="#fff" d="M18.5 10H21.5V30H18.5z" transform="rotate(-134.999 20 20)"></path>
      <path fill="#fff" d="M18.5 10H21.5V30H18.5z" transform="rotate(-45.001 20 20)"></path>
    </svg>
  )
};


export default ProgressBarSVGIcons;
