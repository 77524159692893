import { findResource, search } from "../../services";
import { AppActions, setLoader } from "./";
import { enqueueSnackbar } from "./notify.actions";
import { resourceTypes } from "../types";
import { GetSession, SaveSession } from "../../helpers";
import { siteTypes } from "../../constants";
import moment from 'moment-timezone';
import { DISABLE_API_LOADER, appDefaults } from "../../config";

const defaultApiLoaderTime = 30;


export const updateResourceFilter = data => dispatch => {
  dispatch({ type: resourceTypes.FILTERS, payload: data });
};

export const getResourceDetails = (id, year, month, mp, lang, category) => {
  return async (dispatch) => {
    try {
      findResource({ id, year, month, mp, language: lang, category }, dispatch)
        .then(resp => {

        }).catch((err) => {
          if (err.response) {
            dispatch(
              enqueueSnackbar({
                message: err.response.data?.error,
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: "error",
                },
              })
            );
            dispatch({ type: AppActions.HIDE_PB });
            throw new Error(err.response.data?.error);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const searchResources = (data) => {
  const site = JSON.parse(GetSession("site"));
  data["category"] = site?.type;
  return (dispatch) => {
    try {
      !DISABLE_API_LOADER && dispatch(setLoader('apiLoader', true))
      search({ ...data, detail_level: 15, method: 'list_resources', listPublishedOnly: true }, dispatch)
        .then((resp) => {
          // let list = !PLANYO ? resp?.data : [];
          let list = resp?.data;
          // if (resp?.data?.resources) {
          //   Object?.keys(resp?.data?.resources).forEach(x => {
          //     list.push(resp?.data?.resources[x]);
          //   })
          // }

          // if(resp?.data?.resources){ //to be kept in API service
          //   list=Object?.values(resp?.data?.resources);
          // }
          dispatch(setLoader('apiLoader', false))
          if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
          dispatch({ type: resourceTypes.RESOURCES, payload: list });

          let siteType = '';
          if (data.siteType === siteTypes.SERVICE) siteType = 'SERVICES_LIST';
          else if (data.siteType === siteTypes.EVENT) siteType = 'EVENTS_LIST';
          else if (data.siteType === siteTypes.SPACE) siteType = 'SPACES_LIST';
          else siteType = 'ROOMS_LIST';
          dispatch({ type: siteType, payload: list });
        }).catch(() => {
          if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
        });
    } catch (err) {
      // dispatch(setLoader('pageLoader',false))
      console.log(err);
      if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
    }
  }
};

export const getresourcesCountMeetingPlace = (data, dispatch = null) => {
  // return (dispatch) => {
  try {
    return search({ ...data, detail_level: 15, method: 'list_resources', listPublishedOnly: true }, dispatch)
      .then((resp) => {
        let siteType = '';
        if (data?.siteType === 'Services') siteType = 'SERVICES_LIST';
        else if (data?.siteType === 'Events') siteType = 'EVENTS_LIST';
        else if (data?.siteType === 'Spaces') siteType = 'SPACES_LIST';
        else siteType = 'ROOMS_LIST';

        dispatch({ type: siteType, payload: resp?.data?.resources });
        // const resourceCount = !PLANYO ? resp?.data?.length : resp?.data?.resource_count;
        const resourceCount = resp?.data?.length;
        if (resourceCount) {
          if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
          return Promise.resolve({ count: resourceCount, siteId: data?.siteId });
        } else {
          if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
        }
      }).catch(() => {
        if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
      });
  } catch (err) {
    console.log(err);
    if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
  }
  // }
};

export const getSiteInfo = (data, dispatch = null) => {
  try {
    // return search({ ...data, headers: { Planyo: true }, method: 'get_site_info' })
    return search({ ...data, method: 'get_site_info' })
      .then((resp) => {
        //  {
        return Promise.resolve({
          openingHours: resp?.data?.properties?.opening_hours,
          phone: resp?.data?.properties?.phone,
          gpsCoords: resp?.data?.properties?.gps_coords,
          email: resp?.data?.properties?.email,
          timeZone: resp?.data?.timezone,
          timezoneOffset: resp?.data?.timezone_offset,
          sortBy: resp?.data?.properties?.sortby ? JSON.parse(resp?.data?.properties?.sortby) : {},
          formFields: resp?.data?.properties?.custom_reservation_form_fields ? JSON.parse(resp?.data?.properties?.custom_reservation_form_fields) : {},
          // siteId: data?.siteId
        }).then((resp) => {
          // setting the default time and the fallback timezone is Europe/London
          moment.tz.setDefault(resp?.timeZone || appDefaults?.timeZone);
          SaveSession('siteInfoDisplay', JSON.stringify(resp));
          if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
        }).catch(() => {
          if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
        });
        // }
      });
  } catch (err) {
    console.log(err);
    if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
  }
}

export const getTotalResourcesCountMeetingPlace = (list, dispatch = null) => {
  try {
    let promises = list.map(l => getresourcesCountMeetingPlace(l, dispatch))
    let result = Promise.all(promises);
    return result;
  } catch (err) {
    console.log(err);
  }
}

export const filterResources = (data) => {
  return (dispatch) => {
    try {
      if (dispatch) { setTimeout(() => { !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true }); }, defaultApiLoaderTime); }
      search({ ...data, detail_level: 29, method: 'resource_search', quantity: 1, category: JSON.parse(GetSession('site')).type }, dispatch)
        .then((resp) => {
          let list = [];
          if (resp?.data?.results) {
            Object?.keys(resp?.data?.results).forEach(x => {
              list.push(resp?.data?.results[x]);
            })
          }
          if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
          dispatch({ type: resourceTypes.RESOURCES, payload: list });
        }).catch(() => {
          if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
        });

    } catch (err) {
      console.log(err);
      if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
    }
  }
};
