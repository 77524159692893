/***
 *
 * Controller class for user.
 * @file StyledTextField.js
 * @description StyledTextField component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import FormFieldLabel from '../FormFieldLabel';
// import PropTypes from 'prop-types';


export const StyledTextField = ({
  className, error, fullwidth = true, id, type, placeholder,
  disabled, required = false, value, variant, size, inputSxProp,
  adornment = null, noBorder = false, ariaAttributes = {},
  inputProps, InputProps, InputLabelProps, isPopperOpen = false,
  onChange = () => { }, readOnly = false, label = { id: '', text: '' },
}) => {

  const inputSx = {
    color: '#474747',
    padding: '11px 24px',
    backgroundColor: '#ffffff',
    border: noBorder ? 'none' : `1px solid #767571`,
    borderRadius: '24px',
    fontFamily: 'Noto IKEA Latin',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    '&:hover': {
      border: noBorder ? 'none' : `2px solid #767571`,
    },
    '&:autofill': {
      borderRadius: '24px',
    },
    '&:-webkit-autofill': {
      borderRadius: '24px',
    },
    '&:-internal-autofill-selected': {
      borderRadius: '24px',
    },
    "&::-webkit-input-placeholder": {
      color: '#474747'
    },
    '&:focus-within': {
      outlineOffset: '3px',
      outline: isPopperOpen ? 'none' : '3px solid #767571',
      borderBottom: isPopperOpen ? '1px solid transparent' : '',
      borderRadius: isPopperOpen ? '24px 24px 0 0' : '24px'
    },
    '&.Mui-disabled': {
      color: readOnly ? '#474747' : '#ADAAA3',
      backgroundColor: readOnly ? '#EAE3DA' : '#FFFFFF',
      border: readOnly ? 'none' : '1px solid #D7CFC6'
    },
    '&[aria-invalid="true"]': {
      border: '1px solid #CC0511',
      '&:hover': {
        border: '2px solid #CC0511',
      },
      '&:focus-within': {
        outlineColor: isPopperOpen ? 'none' : '#CC0511',
      },
    },
    ...inputSxProp
  }

  const inputPropsObj = inputProps ? inputProps : adornment ? {} : { sx: inputSx };
  const textFieldSx = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiInputBase-inputAdornedEnd': {
      padding: 0,
      "&::-webkit-input-placeholder": {
        color: '#474747'
      }
    },
    '& .MuiInputBase-inputAdornedStart': {
      padding: 0,
      "&::-webkit-input-placeholder": {
        color: '#474747'
      }
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: readOnly ? '#474747' : '#ADAAA3',
    }
  };

  return (
    <>
      {label?.text && (
        <FormFieldLabel id={label?.id} text={label?.text} />
      )}
      <TextField
        className={className}
        required={required}
        error={error}
        fullWidth={fullwidth}
        hiddenLabel
        placeholder={placeholder}
        id={id}
        type={type}
        disabled={disabled}
        value={value}
        variant={variant}
        size={size}
        inputProps={{ ...inputPropsObj, ...ariaAttributes }}
        InputProps={
          InputProps ? InputProps : (
            adornment ? {
              sx: inputSx,
              endAdornment: adornment?.position === "end" ? (
                <InputAdornment position={adornment?.position}>
                  {adornment?.component}
                </InputAdornment>) : null,
              startAdornment: adornment?.position === "start" ? (
                <InputAdornment position={adornment?.position}>
                  {adornment?.component}
                </InputAdornment>) : null
            } : {}
          )
        }
        InputLabelProps={InputLabelProps}
        sx={textFieldSx}
        onChange={onChange}
      />
    </>
  )
};

StyledTextField.propTypes = {};

StyledTextField.defaultProps = {};

export default StyledTextField;
