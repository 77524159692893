import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router'

import { authentication } from './authentication.reducer';
import appReducer from './app.reducer';
import bookingReducer from './booking.reducer';
import resourcebookingReducer from './resource.reducer';
import dataReducer from './data.reducer';

const rootReducer = combineReducers({
    authentication,
    app: appReducer,
    booking: bookingReducer,
    resource: resourcebookingReducer,
    // router: connectRouter(history),
    data: dataReducer
});

export default rootReducer;