import { appDefaults } from "../../config";
import { GetSession, RemoveSession, SaveLocal, SaveSession } from "../../helpers";
import { sessionTypes } from "../types/session.types";
import language from '../../data/language.json';

function prefix(action) {
  return `APP_${action}`;
}
// will be deleted in future!
export const AppActions = {
  LOAD: prefix("LOAD"),
  MENU: prefix("MENU"),
  ERROR: prefix("ERROR"),
  LANG: prefix("LANG"),
  POPUP: prefix("POPUP"),
  RESET_ERR: prefix("RESET_ERR"),
  SHOW_PB: prefix("SHOW_PB"),
  HIDE_PB: prefix("HIDE_PB"),
  ALERT: prefix("ALERT"),
};

const setCountry = (country) => {
  return {
    type: sessionTypes.COUNTRY,
    payload: country
  };
};

const setMeetingPlace = (meetingPlace) => {
  return {
    type: sessionTypes.MEETING_PLACE,
    payload: meetingPlace,
  };
};

const setSite = (site) => {
  if (!site || !site?.siteId) {
    RemoveSession("site");
  }
  return {
    type: sessionTypes.SITE,
    payload: site
  }
}

const setLanguage = (receivedLanguage = appDefaults?.language) => {
  const isValidLanguageCode = language?.find(item => item?.code === receivedLanguage?.toUpperCase()) ? true : false;
  receivedLanguage = isValidLanguageCode ? receivedLanguage : appDefaults?.language;
  SaveSession('selectedLanguage', receivedLanguage);
  if (receivedLanguage) { SaveLocal('selectedLanguage', receivedLanguage); }
  return {
    type: sessionTypes.LANGUAGE,
    payload: receivedLanguage?.toLowerCase(),
  };
};

const setPage = (page) => {
  const cPage = JSON.parse(GetSession("currentPage") || "{}");
  if (page?.key !== cPage?.key) {
    SaveSession("prevPage", GetSession("currentPage"));
    setTimeout(() => {
      SaveSession("currentPage", JSON.stringify(page));
    }, 1);
  }
  return {
    type: sessionTypes.CURRENT_PAGE,
    data: page,
  };
};

const setError = (error) => {
  return {
    type: sessionTypes.APP_ERROR,
    payload: error,
  };
}

const showHideAlert = (title, message) => {
  return {
    type: sessionTypes?.ALERT,
    payload: {
      title: title,
      message: message,
    },
  };
};
const setLoader = (type, state) => {
  return {
    type: type,
    payload: state
  }
}
export { showHideAlert, setLanguage, setPage, setError, setCountry, setMeetingPlace, setSite, setLoader };
