import { Cookies } from 'react-cookie';
import { isServer } from './server.helper';

// // import localStorage from 'localStorage';
// import sessionstorage from 'sessionstorage';

const cookies = new Cookies();

// export function GetSession(key=null) {
//     if(typeof window !== 'undefined') { 
//         if(key) { return sessionStorage.getItem(key); }
//         else { return sessionStorage; }
//     } else {
//         // if(key) { return JSON.stringify(localStorage.getItem(key)); }
//         // else { return JSON.stringify(localStorage); }
//         if(key) { return sessionstorage.getItem(key); }
//         else { return sessionstorage; }
//     }    
// }

// export function SaveSession(key, value) {
//     if(typeof window !== 'undefined') {  
//         sessionStorage.setItem(key, value);
//     } else {
//         // localStorage.setItem(key, value);
//         sessionstorage.setItem(key, value);
//     }     
// }

// export function RemoveSession(key) {
//     if(typeof window !== 'undefined') {  
//         sessionStorage.removeItem(key);
//     } else {
//         // localStorage.removeItem(key);
//         sessionstorage.removeItem(key);
//     } 
// }


export function SaveSession(key, value) {
    if (!isServer) {
        sessionStorage.setItem(key, value);
    } else {
        cookies.set(key, value);
    }

}

export function GetSession(key = null) {
    if (!isServer) {
        if (key) { return sessionStorage.getItem(key); }
        else { return sessionStorage; }
    } else {
        cookies.get(key);
    }

}

export function RemoveSession(key) {
    if (!isServer) {
        sessionStorage?.removeItem(key);
    } else {
        cookies?.remove(key);
    }

}