import { userTypes } from '../types'


const initialState = {
    loggedIn: false,
    userDetails: {}
};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userTypes.AUTHENTICATION:
            return {
                ...state,
                loggedIn: action.payload,
            };
        case userTypes.LOGIN_OR_SIGNUP:
            return {
                ...state,
                userDetails: {
                    planyoUserId: state?.userDetails?.planyoUserId,
                    ...action.payload
                }
            };
        case userTypes.SET_PLANYO_USER_ID:
            return {
                ...state,
                userDetails: {
                    ...state?.userDetails,
                    planyoUserId: action.payload
                }
            }
        case userTypes.LOGOUT:
            return initialState;
        default:
            return state
    }
}