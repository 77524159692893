import { dataTypes } from "../types/data.types";


export const setMeetingPlaces = data => dispatch => {
  dispatch({ type: dataTypes.MEETING_PLACES, payload: data });
};

export const setCountries = data => dispatch => {
  dispatch({ type: dataTypes.COUNTRIES, payload: data });
};

export const setPhonePrefixes = data => dispatch => {
  dispatch({ type: dataTypes.PHONE_PREFIXES, payload: data });
};