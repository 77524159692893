/***
 *
 * Controller class for user.
 * @file Header.js
 * @description Header component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import TopNavigation from '../TopNavigation';

const Header = ({ topMenu, isExternalRoute }) => {

  const [navStyle, setNavStyle] = useState({ backgroundColor: "#eae3da" });

  useEffect(() => {
    if (topMenu || isExternalRoute) {
      setNavStyle({
        backgroundColor: "#ffffff",
        zIndex: "999"
      });
    }
  }, [topMenu, isExternalRoute])

  return (
    <TopNavigation topMenu={topMenu} style={navStyle} />
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
