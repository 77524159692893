/***
 *
 * Controller class for user.
 * @file LanguageSelector.js
 * @description LanguageSelector component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Link, Breadcrumbs, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import './LanguageSelector.scss';
import { setCountry, setLanguage } from '../../redux/actions';
import { useTranslation } from "react-i18next";
import { Navigate, GetSession, history } from '../../helpers';
import moment from 'moment';
import countries from '../../data/countries.json'
import LanguageDropdown from '../LanguageDropdown/index';
import { fetchLanguage } from '../../helpers/language.helper';
import { appDefaults } from '../../config';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/pl';
import 'moment/locale/sk';
import 'moment/locale/sv';
import 'moment/locale/fr';
import 'moment/locale/cs';


const LanguageSelector = ({ topMenu }) => {

  // const GAEventTracker = useGAEventTracker("User Action");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { i18n } = useTranslation();
  const [languageData, setLanguageData] = useState(null);
  // const authentication = useSelector(state => state.authentication);
  const app = useSelector(state => state.app);
  const router = useSelector(state => state.router);
  const dispatch = useDispatch();

  const languageBreadcrumbActive = {
    color: "#474747",
    fontWeight: "700",
    textDecoration: "underline",
    textDecorationThickness: "2px",
    textUnderlineOffset: "3px"
  }

  const onLanguageChangeHandler = (event) => {
    let cont = JSON.parse(GetSession('country'));
    const currentPage = GetSession("currentPage");
    if (currentPage == null) {
      for (let key of countries) {
        if (event?.code?.toUpperCase() === appDefaults?.language?.toUpperCase()) {
          cont = appDefaults?.country;
          break;
        } else if (key?.languages?.includes(event?.code?.toLowerCase())) {
          cont = key;
        } else continue;

      }
    }

    i18n.changeLanguage(event?.code?.toLowerCase() + "-" + cont?.code);
    moment.locale(event?.code?.toLowerCase());

    dispatch(setCountry(cont));
    dispatch(setLanguage(event?.code));
    if (app?.page?.route === '404' || history?.location?.pathname?.includes('/404')) {
      // window?.location.reload();
      Navigate(20000, { dispatch, history, pageReload: false });
    } else if (app?.page?.route === '/under-maintenance') {
      Navigate(30000, { dispatch, history, pageReload: false });
    }
    else if (Object?.keys(app?.page)?.length > 1) {
      let id = router?.location?.pathname?.split('/') || app?.page?.excetPath?.split("/");
      const emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (id && emailregex.test(id?.[id?.length - 1])) {
        Navigate(app?.page?.id, { dispatch, history, id: id[id?.length - 2], email: id[id?.length - 1], pageReload: false })
      } else {
        if (id && (id?.[id?.length - 1]?.search(/\d/) !== -1)) {
          Navigate(app?.page?.id, { dispatch, history, id: id[id?.length - 1], pageReload: false })
        } else if (app?.page?.id === 1000 || app?.page?.id === 1001) {
          Navigate(1001, { dispatch, history, excetPath: app?.page?.excetPath, pageReload: false });
        }
        else {
          Navigate(app?.page?.id, { dispatch, history, pageReload: false })
        }
      }
    } else if (app?.page?.id === 1000 || app?.page?.id === 1001 || Object?.keys(app?.page)?.length < 1) {
      Navigate(1001, { dispatch, history, excetPath: app?.page?.excetPath, pageReload: false });
    }
  };

  useEffect(() => {
    setLanguageData(fetchLanguage({ country: app?.country }));
  }, [app?.country, GetSession("currentPage")])

  useEffect(() => {
    moment.locale(app?.selectedLanguage?.toLowerCase());
    if (app?.page?.id && app?.page?.id === '10000' && app?.page?.id === '7000' && app?.page?.id === '6000') {
      Navigate(app?.page?.id, { dispatch, history, excetPath: app?.page?.excetPath });
    } else if (app?.page?.id === 1000 || app?.page?.id === 1001) {
      Navigate(1001, { dispatch, history, excetPath: app?.page?.excetPath });
    }
  }, [app?.selectedLanguage])

  return (
    <Box className={"LanguageSelector"} data-testid="LanguageSelector">
      {
        (matches || !topMenu) ? (
          <Box className={"profileDetails"}>
            <LanguageDropdown
              title={app?.selectedLanguage?.toUpperCase()}
              list={languageData}
              onClick={onLanguageChangeHandler}
              topMenu={topMenu}
            />
          </Box>) :
          (<>
            <Breadcrumbs aria-label="Language Selector">
              {
                languageData && languageData?.map((item, index) => {
                  return (
                    <Link
                      tabIndex={0}
                      key={index}
                      underline="none"
                      name={item?.name}
                      color="#767571"
                      variant='subtitle2'
                      onClick={(e) => { onLanguageChangeHandler(item); }}
                      style={(app?.selectedLanguage?.toLowerCase() === item?.code?.toLowerCase()) ? languageBreadcrumbActive : {}}
                      aria-label={languageData?.find(currentItem => currentItem?.code?.toLowerCase() === item?.code?.toLowerCase())?.name}
                      component="button"
                    >
                      {item?.code}
                    </Link>)
                })
              }
            </Breadcrumbs>
          </>)
      }
    </Box>
  )
};

LanguageSelector.propTypes = {};

LanguageSelector.defaultProps = {};

export default LanguageSelector;
