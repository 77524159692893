/***
 *
 * Controller class for user.
 * @file MeetingPlaceDropDownList.js
 * @description MeetingPlaceDropDownList component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import './MeetingPlaceDropDownList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getSiteInfo, setMeetingPlace, setSite } from '../../redux/actions';
import { Navigate, history } from '../../helpers';
import { navigationTypes } from '../../redux/types';
import { useTranslation } from 'react-i18next';
import { delay } from '../../services';
import moment from 'moment-timezone';

const MeetingPlaceDropDownList = () => {

  const { t } = useTranslation()
  const [meetingPlaceList, setMeetingPlaceList] = useState([]);

  const app = useSelector(state => state.app);
  const meetingPlaces = useSelector(state => state?.data?.meetingPlaces);
  const dispatch = useDispatch();

  /**
   * @function getUTCOffset
   * @description finds the UTC offset from the timestamp
   * @example getUTCOffset("2013-11-18T11:55:00+08:00") => "+08:00"; 
   * @returns {string} returns the UTC offset
   */
  const getUTCOffset = (timeZone) => {
    const regex = /[+-]\d{2}:\d{2}$/;
    const offset = moment?.tz(timeZone || "CET")?.format()?.match(regex)?.[0];
    if (offset) return `UTC${offset}`;
    return "";
  }

  const selectHandler = (event) => {
    const meetingPlace = meetingPlaceList.find(place => place.mpid === event.target?.value);
    Promise.resolve().then(() => {
      dispatch(setMeetingPlace(meetingPlace));
    }).then(() => {
      dispatch(setSite({}));
    }).then(delay(200)).then(() => {
      Navigate(navigationTypes.GENERALBOOKINGPAGE, { dispatch, history });
    });
  }

  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document?.addEventListener("click", handleClickOutside, false);
    return () => {
      document?.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (app?.site?.siteId && app?.meetingPlace?.mpid) {
      getSiteInfo({ siteId: app?.site?.siteId, category: app?.site?.type }, dispatch);
    }
  }, [app?.site?.siteId])

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    setMeetingPlaceList(meetingPlaces?.filter(item => item?.countryCode === app?.country?.code) || []);
  }, [meetingPlaces, app]);

  return (
    <div ref={ref} className={"MeetingPlaceDropDownList"} data-testid="MeetingPlaceDropDownList">
      <div className={"selectionArea"} >
        <div className={"heading"}>{app?.meetingPlace?.name}</div>
        {/* <div className='text'>{`${t("Timezone")}: ${moment?.tz(moment()?.tz())?.zoneAbbr()}(${moment()?.tz()})`}</div> */}
        {/* {app?.meetingPlace?.openAt && <div className={"text"}>{t("S_OPEN_TODAY")} {`${app?.meetingPlace?.openAt || ""} ${moment()?.tz() !== moment?.tz?.guess() ? `| ${app?.meetingPlace?.timeZone || ""}; UTC${getUTCOffset(app?.meetingPlace?.timeZone)}` : ''}`}</div>} */}
        {(app?.meetingPlace?.timeZone || app?.meetingPlace?.openAt) && <div className={"text"}>{app?.meetingPlace?.openAt ? t("S_OPEN_TODAY") + ` ${app?.meetingPlace?.openAt}` : ""} {`${moment()?.tz() !== moment?.tz?.guess() ? `${(app?.meetingPlace?.openAt ? '| ' : '') + app?.meetingPlace?.timeZone}; ${getUTCOffset(app?.meetingPlace?.timeZone)}` : ''}`}</div>}
      </div>
      {
        isVisible && (
          <ul>
            {meetingPlaceList?.map((mp, index) => {
              return (<li key={index} onClick={() => selectHandler({ target: { value: mp?.mpid } })}>
                <span>{mp?.name}</span>
              </li>)
            })}
          </ul>
        )
      }
    </div>
  )
};

MeetingPlaceDropDownList.propTypes = {};

MeetingPlaceDropDownList.defaultProps = {};

export default MeetingPlaceDropDownList;
