/***
 *
 * Controller class for user.
 * @file DropDown.js
 * @description DropDown component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import './LanguageDropdown.scss';
import classNames from 'classnames';
import { Box, Menu, MenuItem } from '@mui/material';
import StyledButton from '../StyledButton';

const LanguageDropdown = ({ title = "", list = [], onClick = () => { }, topMenu = false }) => {


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuSx = {
    width: "150px",
    backgroundColor: "#F7F4EF",
    border: '1px solid #d2d2d2',
    borderRadius: "20px",
    boxShadow: "0 4px 4px rgb(0 0 0 / 12%)"
  }

  const buttonSx = {
    borderRadius: '50%',
    minWidth: '50px',
    height: '50px',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '14px',
    fontWeight: 700,
    fontFamily: 'Lively Headline',
  }

  return (
    <>
      <Box data-testid="LanguageDropDown" className={classNames("dropdownContainer", "langDropdownStyle")} >
        <StyledButton
          sx={buttonSx}
          variant={topMenu ? 'secondary' : 'primary'}
          id="language-dropdown"
          onClick={handleClick}
          aria-label={list?.find(item => item?.code?.toLowerCase() === title?.toLowerCase())?.name}
        >
          {title}
        </StyledButton>
        <Menu
          className="overrideStyleMenu"
          id="language-menu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          anchorEl={anchorEl}
          open={list && list?.length > 1 ? open : false}
          onClose={handleClose}
          PaperProps={{
            style: {
              ...menuSx
            }
          }}
        >
          {
            list && list.map((item, index) => {
              return (
                <MenuItem
                  className={"overrideStyleItem"}
                  onClick={(e) => { e.target.blur(); onClick && onClick(item); handleClose() }}
                  key={index}>
                  {item.name}
                </MenuItem>
              )
            })
          }
        </Menu>
      </Box >


    </>

  )
};

LanguageDropdown.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array, //it should contain id and text
  onClick: PropTypes.func,
};

LanguageDropdown.defaultProps = {};

export default LanguageDropdown;


