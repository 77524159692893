import { GetSession } from "./session.helper";
import language from '../data/language.json';
import countries from '../data/countries.json';
import { ENABLE_LOCAL_LANGUAGE_ONLY } from "../config";

export const fetchLanguage = ({ country }) => {
    if (GetSession("currentPage") !== null && country?.code) {
        const countryLanguage = (countries?.find(item => item?.code === country?.code))?.languages;
        // const result = countryLanguage?.map(item => {
        //     if (languageFlag) {
        //         if ((item !== 'en' && country?.code !== 'GB') || (item === 'en' && country?.code === 'GB')) {
        //             return language?.find(lang => lang?.code?.toLowerCase() === item?.toLowerCase())
        //         }
        //     } else {
        //         return language?.find(lang => lang?.code?.toLowerCase() === item?.toLowerCase())
        //     }
        // });
        const resultArr = [];
        countryLanguage?.forEach(item => {
            if (ENABLE_LOCAL_LANGUAGE_ONLY) {
                if ((item !== 'en' && country?.code !== 'GB') || (item === 'en' && country?.code === 'GB')) {
                    resultArr.push(language?.find(lang => lang?.code?.toLowerCase() === item?.toLowerCase()))
                }
            } else {
                resultArr.push(language?.find(lang => lang?.code?.toLowerCase() === item?.toLowerCase()))
            }
        });
        return resultArr;
    } else {
        return language;
    }
}