import { appDefaults } from "../config";
import countries from '../data/countries.json';
import meetingPlaces from "../data/meetingPlace.json";
import phonePrefixList from '../data/phonePrefixList.json';
import { formatCountries, formatMeetingPlace, formatPhonePrefix, formatSites } from "./util.service";
import { siteTypes } from "../constants";

/**
 * @function fetchSites
 * @description function to process the translations of heading, subheading, button text, terms and condition (if any)
 * @param {object} app object that holds the configuration of the application
 * @param {function} t translation function of i18n
 * @param {function} cb callback function for returning the processed data
 */
export const fetchSites = (app, t, cb) => {

  const list = formatSites(appDefaults?.sites)?.filter(item => {
    return item?.meetingPlaceId === app?.meetingPlace?.mpid;
  });

  const result = list.map(s => {
    let content = '';
    let heading = '';
    let name = '';

    if (s?.type === siteTypes.SERVICE) {
      content = t("S_BOOK_A_WHEEL_CHAIR_AND_MORE");
      heading = t("S_SERVICES");
      name = `${app?.meetingPlace?.name} ${t("S_SERVICES")}`
    }
    else if (s?.type === siteTypes.EVENT) {
      content = t("S_BOOK_YOUR_SPOT_IN_OUR_EVENTS");
      heading = t("S_EVENTS");
      name = `${app?.meetingPlace?.name} ${t("S_EVENTS")}`
    }
    else {
      content = t("S_FIND_THE_PERFECT_AREA");
      heading = t("S_SPACES");
      name = `${app?.meetingPlace?.name} ${t("S_SPACES")}`
    }
    return {
      heading: heading,
      name: name,
      buttonText: t("S_DISCOVER_MORE"),
      disableButton: false,
      siteId: s?.siteId,
      type: s?.type,
      content: content,
      termsAndConditions: s?.termsAndConditions
    }
  });
  cb(result);
}

/**
 * @function fetchCountries
 * @description function that return the processed countries data
 * @param {function} cb a callback function that returns the sanitiezed countries data
 */
export const fetchCountries = (cb) => {
  cb(formatCountries(countries));
}
/**
 * @function fetchMeetingPlaces
 * @description function that return the processed meeting place data
 * @param {function} cb callback function that returns the sanitiezed meeting places data
 */
export const fetchMeetingPlaces = (cb) => {
  cb(formatMeetingPlace(meetingPlaces));
}

/**
 * @function fetchSitesData
 * @description function that return the processed meeting place sites data
 * @param {function} cb callback function that returns the sanitiezed meeting place sites data
 */
export const fetchSitesData = (cb) => {
  cb(formatSites(appDefaults?.sites))
}
/**
 * @function fetchPhonePrefixList
 * @description function the return the processed phone prefix list data
 * @param {function} cb callback function that returns the sanitiezed phone prefix data
 */
export const fetchPhonePrefixList = (cb) => {
  cb(formatPhonePrefix(phonePrefixList));
}