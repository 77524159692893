/***
 *
 * Controller class for user.
 * @file ProgressBarIcon.js
 * @description ProgressBarIcon component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBarIcon.scss';
import classNames from 'classnames';
import ProgressBarSVGIcons from '../ProgressBarSVGIcons/index';

const sizeToClassName = {
  xsmall: 'XSmall',
  small: 'Small',
  medium: 'Medium',
  normal: 'Normal',
  large: 'Large',
  xlarge: 'XLarge',
  xxlarge: 'XXLarge'
};

const ProgressBarIcon = (props) => {

  const icon = ProgressBarSVGIcons[props.children];
  const sizeClass = sizeToClassName[props.size] || "Medium";

  if (!icon) {
    return '';
  }
  else if (icon.type === 'svg') {
    return icon;
  } else {
    return (
      <svg
        viewBox='0 0 24 24'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        data-testid="ProgressBarIcon"
        className={classNames("ico_icon", sizeClass)}
      >
        <title>{props.children}</title>
        <g className={props.rotate ? "Rotate" : ''}>{icon}</g>
      </svg>
    );
  }
};

ProgressBarIcon.propTypes = {
  className: PropTypes.string,
  rotate: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.string.isRequired
};

ProgressBarIcon.defaultProps = {};

export default ProgressBarIcon;
