export const sessionTypes = {
    ALERT: "alert",
    CURRENT_PAGE: "currentPage",
    MEETING_PLACE: "meetingPlace",
    LANGUAGE: "selectedLanguage",
    COUNTRY: "country",
    SITE: "site",
    SET_STATUSANDMESSAGE: "setStatusAndMessage",
    SET_PAGE: "setPage",
    ROUTE: "route",
    ROUTEID: "routeId",
    APILOADER: "apiLoader",
    APPLOADER: "appLoader",
    PAGELOADER: "pageLoader",
    COMPLOADER: "compLoader",
    FUNLOADER: "funLoader",
    CLEAN_SITE_DETAILS: 'cleanSite',
    CLEAN_MEETING_PLACE_DETAILS: 'cleanMeetingPlace',
    CLEAN_PAGE_DETAILS: 'cleanPage',
    CONFIGURATION: "configuration",
    APP_ERROR: 'app_error',
    ICIM_ACTION_ERROR: 'icimActionError',
    EXIT_BOOKING_FLOW: 'exitBookingFlow',
    PORTAL: "portal"
}