/***
 *
 * Controller class for user.
 * @file QrAction.js
 * @description QrAction component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import styles from './QRAction.module.scss';
import { Grid, Paper, Typography } from '@mui/material';
import { DISABLE_API_LOADER } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { search } from '../../services';
import { useNavigate } from '../../hooks/navigate.hooks';
import { useParams } from 'react-router-dom';
import { GetSession, RemoveSession } from '../../helpers';

const QRAction = ({ id }) => {
  const dispatch = useDispatch();
  const app = useSelector(state => state?.app);
  const setNavigation = useNavigate();
  const params = useParams();
  const defaultApiLoaderTime = 100;
  const [isCheckIn, setIsCheckIn] = useState(false);

  const containerSx = {
    display: 'flex',
    flexDirection: 'column',
    px: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    my: 'auto'
  }


  const paperSx = {
    boxShadow: '0px 0px 10px rgba(118, 117, 113, 0.3)',
    borderRadius: '20px',
    backgroundColor: '#eae3da',
    position: 'absolute',
    display: 'flex',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  useEffect(() => {
    setNavigation(params);
  }, [!app?.page?.id]);

  useEffect(() => {
    funcAPICall(id)?.then(res => {
      if (res && res?.data) {
        setIsCheckIn(true);
      }
      console.log("response", res);
    })?.catch(err => {
      console.log("error", err);
    })
  }, [])

  const funcAPICall = (id) => {
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    const queryString = JSON.parse(GetSession("queryParams"));
    const queryParams = queryString?.paramString?.split('?')[1]?.split('&');
    const reservationId = queryParams?.find(item => item?.includes('reservation'))?.split('=')[1];
    const action = queryParams?.find(item => item?.includes('action'))?.split('=')[1];
    const siteId = queryParams?.find(item => item?.includes('site'))?.split('=')[1];

    RemoveSession('isAuthUser');

    if (queryString && reservationId && action && siteId) {
      return new Promise((resolve, reject) => {
        search({
          method: "do_reservation_action",
          reservationId: reservationId,
          action: action,
          siteId: siteId,
          headers: { Planyo: true }
        })
          .then((resp) => {
            if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
            resolve(resp);
          }).catch((err) => {
            if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, defaultApiLoaderTime); }
            reject(err);
          });
      })
    }
  }

  return (
    <Grid container sx={{ xs: 12, md: 6 }} display={'flex'} justifyContent={'center'}>
      <Paper variant={'primary'} sx={{ ...paperSx, width: { md: 610, sm: 400, xs: 300 }, minHeight: { md: 400, xs: 600 } }}>
        <Grid container sx={containerSx} direction={'column'} alignItems='center' justifyContent={'center'} component={'form'}>
          <Grid item mb={2}>
            {isCheckIn ?
              <Typography align='center' variant='h3'>User Checked In Successfully</Typography>
              :
              <Typography align='center' variant='h3'>Loading...</Typography>
            }
          </Grid>
        </Grid>
      </Paper >
    </Grid >
  )
};


QRAction.propTypes = {};

QRAction.defaultProps = {};

export default QRAction;
