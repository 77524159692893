import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GetLocal, GetSession, SaveSession, history, isServer } from '../helpers';
import { setCountry, setLanguage, setMeetingPlace, setPage, setSite } from '../redux/actions';
import routes from '../routes/routes';
import { fetchCountries, fetchMeetingPlaces, fetchSitesData } from '../services/json.service';
import { pushToDataLayer } from '../services/analytics.service';
import { dataLayerEvents } from '../constants';
import { ENABLE_LOCAL_LANGUAGE_ONLY } from '../config';
import { sessionTypes } from '../redux/types';

export const useNavigate = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const app = useSelector(state => state?.app);

  const countryCode = JSON.parse(GetSession("country") || "{}")?.code?.toUpperCase();
  const defaultLangCountry = `${GetSession("selectedLanguage")?.toLowerCase() || "en"}-${countryCode}`

  const setRouteLanguageCountry = (language, countryCode) => {
    let routeArr = location?.pathname?.split('/');
    let lc = routeArr[1]?.split("-");
    lc[0] = language;
    lc[1] = countryCode ? countryCode : lc[1]?.toUpperCase();
    routeArr[1] = lc.join("-");
    return routeArr;
  }

  const setLocalLanguage = (country) => {
    if (country) {
      let language = "";
      if (ENABLE_LOCAL_LANGUAGE_ONLY) {
        language = country.languages?.find(item => {
          if (country?.code === 'GB') return 'en';
          else {
            return item !== 'en';
          }
        })
      } else {
        if (!country?.languages?.includes(language)) {
          language = country?.languages[0];
        }
      }
      return language;
    }
  }

  const setVirtualPageViewEvent = (meetingPlace, key) => {
    // find the meeting place from session in case no meeting place is present in route
    if (!meetingPlace) {
      meetingPlace = GetSession('meetingPlace') && GetSession('meetingPlace') !== "undefined" ? JSON?.parse(GetSession('meetingPlace'))?.id || "" : "";
    }
    // find the current route
    let currentRoute = routes?.find(r => r.key === key);
    if (!currentRoute && key === '/') {
      currentRoute = routes?.find(r => r?.id === 1001)
    }
    if (meetingPlace || currentRoute?.id === 1001) {
      // first find the meeting place details based on the meeting place name
      let meetingPlaceObj = {};
      fetchMeetingPlaces((res) => {
        meetingPlaceObj = res?.find(m => m?.id?.toLowerCase() === meetingPlace?.toLowerCase());
      });

      // part of analytics
      const queryString = new URLSearchParams(location?.search && !isServer ? location?.search : "");
      const b2bEventApplicable = queryString?.get("prt") === 'b2b' ? true : false;
      if (b2bEventApplicable) {
        SaveSession('portal', 'b2b')
        dispatch({ type: sessionTypes.PORTAL, payload: 'b2b' })
      }

      const fragment = window?.location?.hash || "";
      let paramString = "";
      const userCode = JSON.parse(GetLocal("userCode") || '{}')?.code;
      for (const [key, value] of queryString) {
        if (value !== userCode) {
          paramString += `${key}=${value}&`
        }
      }
      let queryParams = {
        paramString: `${paramString?.length > 0 ? '?' + paramString?.substring(0, paramString?.length - 1) : ''}`,
        fragment: fragment?.length > 0 ? fragment : ''
      }
      if (queryParams?.paramString !== '' || queryParams?.fragment != '') {
        SaveSession('queryParams', JSON.stringify(queryParams));
      } else {
        queryParams = JSON?.parse(GetSession('queryParams')) || { paramString: '', fragment: '' };
      }

      // correct route
      let routeArr = setRouteLanguageCountry();
      let dataLayerObj = {
        eventName: dataLayerEvents?.VIRTUAL_PAGE_VIEW,
        market: routeArr[1],
        meetingPlace: meetingPlaceObj?.name,
        meetingPlaceId: meetingPlaceObj?.mpid,
        pagePath: `${routeArr?.join('/')}${queryParams?.paramString || ''}${queryParams?.fragment || ''}`,
        title: `Booking Tool${meetingPlaceObj?.name ? " | " + meetingPlaceObj?.name : ""}`
      }
      // added exception for booking process as it is explicitly handled in the components itself(due to the addition of extra parameters)
      if (currentRoute?.id !== 5004 && currentRoute?.id !== 5006) {
        pushToDataLayer(dataLayerObj);
      }
    }
  }
  const setData = ({ languageCountry = defaultLangCountry, meetingPlace = null, type = null, key = "/" }) => {
    setVirtualPageViewEvent(meetingPlace, key);
    if (!app?.page?.id) {
      const arr = languageCountry?.split('-');
      // dispatch(setLanguage(arr[0]?.toUpperCase()));
      fetchCountries((res) => {
        const country = res?.find(c => c?.code?.toUpperCase() === arr[1]?.toUpperCase());
        if (country) {
          const language = setLocalLanguage(country);
          dispatch(setLanguage(language));
          dispatch(setCountry(country));
        }
      });
      fetchMeetingPlaces((res) => {
        const meetPlace = res?.find(m => m?.id?.toLowerCase() === meetingPlace?.toLowerCase());
        let country = null;
        let language = arr[0]?.toLowerCase();
        if (meetPlace) {
          fetchCountries((res) => {
            country = res?.find(c => c?.code?.toUpperCase() === meetPlace?.countryCode?.toUpperCase());
            if (country) {
              language = setLocalLanguage(country);
              dispatch(setLanguage(language));
              dispatch(setCountry(country));
            }
          });

          dispatch(setMeetingPlace(meetPlace));
        }

        if (type) {
          fetchSitesData((res) => {
            const st = res?.find(s => s?.type?.toLowerCase() === type?.toLowerCase() && s?.meetingPlaceId === meetPlace?.mpid);
            if (st) dispatch(setSite(st));
          })
        }
        let currentRoute = routes?.find(r => r.key === key)
        let pageRoute = currentRoute ? currentRoute : {};

        let routeArr = setRouteLanguageCountry(language, country?.code);
        pageRoute["excetPath"] = routeArr?.join('/');

        history.replace(pageRoute["excetPath"], { key: `${currentRoute?.key}-${new Date()?.getTime()}`, page: JSON.stringify(currentRoute) });

        // analytics for page_view

        if (key !== '/') {
          dispatch(setPage(pageRoute));
        }
      });
    }
  }

  return setData;
}