import { filters, resource } from "../../models";
import { resourceTypes } from "../types";

const INITIAL_STATE = { ...resource };

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case resourceTypes.RESOURCES:
            return {
                ...state,
                list: action.payload
            }
        case resourceTypes.FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.name]: action.payload.value
                }
            }
        case resourceTypes.CLEAN_FILTERS:
            return {
                ...state,
                filters: {
                    ...filters
                }
            }
        case resourceTypes.SERVICES_LIST:
            return {
                ...state,
                allResources: {
                    ...state.allResources,
                    "service": action.payload
                }
            }
        case resourceTypes.EVENTS_LIST:
            return {
                ...state,
                allResources: {
                    ...state.allResources,
                    "event": action.payload
                }
            }
        case resourceTypes.SPACES_LIST:
            return {
                ...state,
                allResources: {
                    ...state.allResources,
                    "space": action.payload
                }
            }
        case resourceTypes.ROOMS_LIST:
            return {
                ...state,
                allResources: {
                    ...state.allResources,
                    "room": action.payload
                }
            }
        default: return state;
    }
}
export default reducer;