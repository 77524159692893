// https://sahil29.medium.com/writing-and-maintaining-api-layer-in-react-react-native-1929fc16f611
import axios from 'axios';
import { appDefaults, PLANYO } from '../config';
import { GetLocal, GetSession, SaveSession } from '../helpers';
import { apiTypes } from '../redux/types';
import { getCookie } from './util.service';
import { checkUserToken } from './user.service';
import { policyTypes } from '../constants';

const _pendingRequests = {};
let accept = "application/json";
let CancelToken = axios.CancelToken;
let qs = JSON;
let config = {};

/**
 * @function sanitizeParams
 * @description function for sanitizing the API parameter in order to prevent unsafe characters in the API calls.
 * @param {object} params input params
 * @returns {object} sanitized params
 */
const sanitizeParams = (params) => {
  return params;
}

/**
 * @function getDefaultOptions
 * @description function for providing the default options to the API
 * @param {object} options 
 * @returns {object} containing default options
 */
const getDefaultOptions = (options) => {
  return options;
}

/**
 * @function abortPendingRequests
 * @description function for cancelling the duplicate API calls
 * @param {string} key unique identifier for the API call
 * @returns {void} 
 */
const abortPendingRequests = (key) => {
  if (_pendingRequests[key]) {
    _pendingRequests[key]('REQUEST_CANCELLED');
    _pendingRequests[key] = null;
  }
};

/**
 * @function isInvalidToken
 * @description function for checking whether the API response status is unauthorized or not
 * @param {object} response object containing API response
 * @returns {boolean} returns true if API response is unauthorized otherwise false
 */
const isInvalidToken = (response) => {
  if (response.status !== apiTypes.UNAUTHORIZED) {
    return false;
  }

  const authHeader = response.headers.get('WWW-Authenticate') || '';

  return authHeader.includes('invalid_token');
};

/**
 * @function processResponse
 * @description function for processing the received API response. 
 * @param {object} res API response 
 * @returns {object} returns the response object if the API response is valid else if the response is invalid then an object containing data as the key with empty value will be returned.
 */
const processResponse = (res) => {
  if (isInvalidToken(res)) {
    return { data: {} };
  }

  if (res.status === apiTypes.NO_CONTENT) {
    const response = Object.assign({}, res, { data: {} });
    return response;
  }

  return res;
};

//commit 53204bf847df68cd63a7968ff980005c758dcfcb - removed old handleResponseFunction

/**
 * @function handleResponse
 * @description function that takes in the processed response as the input and returns an object containing status, data, error and headers as the key.
 * @param {string} key unique identifier for the API call
 * @param {object} options an object that contains details specifc to an API.
 * @param {object} response object returned from the processResponse function
 * @returns {object} returns an object containing details like status, data errors and headers
 */
const handleResponse = (response = {}) => {
  const { status, data, headers, key } = response;

  let resp = {
    status: status,
    data: data,
    headers: headers
  };

  if (key?.includes('method=list_resources') && data?.resources) {
    resp['data'] = Object?.values(data?.resources);
  } else if (key?.includes('method=get_event_times') && data?.event_times) {
    resp['data'] = Object?.values(data?.event_times);
  }

  // use any dispatchers/method to communicate this data to the store/view
  // dispatch(key, resp)

  return resp;
};

/**
 * @function errorFunction
 * @description function that gets executed when there is an error in API. It saves the error object in session in case of 401 status response or invalid resource ID
 * @param {object} error API response error object
 * @returns {object} returns an object containing error
 */
const errorFunction = (error) => {
  if (error?.message === 'Invalid resource ID' || error?.statusCode === 401) {
    SaveSession("error", error?.statusCode === 401 ? '401' : error.message)
  }
  return { errors: error, response: null };
}

/** 
 * @namespace API
 * @typedef {object} API object containing collection of helper function for constructing and firing the API calls
 */
export const API = {

  /**
    * @memberof API
    * @method getHeaders
    * @description method for creating the header for the API request 
    * @param {string} accessToken unique identifier for wrapper endpoints
    * @returns {object} returns an object containing the header parameters
    */
  getHeaders(accessToken) {
    let headers = {
      Accept: accept,
      'Content-Type': 'application/json',
      // 'Planyo' : PLANYO
      //'Ocp-Apim-Subscription-Key' : decryptSS_Pointer(),
    };

    if (PLANYO) {
      headers['Planyo'] = PLANYO;
    }

    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return headers;
  },

  /**
   * @memberof API
   * @method makeRequest
   * @description function for executing the API request.
   *                - utilizes the header generated from the getHeader function
   *                - generate the axios configuration based on the input parameters and received headers
   * @param {object} path object containing the actual API endpoint and a optional base url
   * @param {string} key unique identifier for API calls
   * @param {object} reqInit contains the request body and query parameters
   * @param {object} options additional options specific to the API call
   * @returns {object} object containing API response
   */
  async makeRequest(path, key = path, reqInit = {}, options = {}) {
    abortPendingRequests(key);
    let accessToken = getCookie("accessToken") || null;
    // if(!PLANYO){
    //     if(!accessToken && path != API_URL_AUTHENTICATE){
    //       await reqToken();
    //       accessToken = getCookie("accessToken");
    //     }
    // }
    let headers = this.getHeaders(accessToken);
    options = getDefaultOptions(options);
    if (options?.headers) {
      headers = { ...headers, ...options.headers };
    }
    const init = Object.assign({}, reqInit, { headers });
    const authentication = JSON.parse(GetLocal("authentication")) || null;
    const redirectRoute = JSON.parse(GetLocal("redirectRoute")) || null;
    if (authentication?.loggedIn && redirectRoute && !redirectRoute?.tokenExpire) {
      checkUserToken(policyTypes?.LOGIN_SIGNUP);
    }

    let axiosConfig = {
      ...init,
      url: path,
      timeout: 30000,
      cancelToken: new CancelToken(function executor(c) {
        _pendingRequests[key] = c;
      })
    };

    if (process.env.NODE_ENV === 'production') { axiosConfig.withCredentials = true; }

    return axios(axiosConfig)
      .then(res => {
        return processResponse(res);
      })
      .then(res => {
        if (key?.includes("ICIM-API-User") || key?.includes("WRAPPER-API") || key?.includes("USER-Validation") || key?.includes("SERVER-Maintainence") || key?.includes("CREATE-CHECKOUT-SESSION")) {
          //icim API response
          return handleResponse(res);
        } else {
          return handleResponse({ ...res.data, headers: res.headers, status: res.status, key });
        }
        // else if (!res?.data?.data && !res?.data?.response_code) {
        //   // wrapperResp
        //   return handleResponse(res);
        // } else if (!res?.data?.data && res?.data?.response_code) {
        //   // planyoErr
        //   return errorFunction({
        //     statusCode: res?.data?.response_code,
        //     message: res?.data?.response_message
        //   });
        // } else {
        //   // planyoResp
        //   const resp = {
        //     status: res?.data?.response_code,
        //     data: res?.data?.data,
        //     headers: res?.headers
        //   };
        //   return handleResponse(resp);
        // }
        // return handleResponse(key, options, res);
      })
      .catch((err) => {
        // error handling logic
        if (appDefaults?.environment !== 'prod') {
          console.log("Path: " + path);
          console.log("Error: " + err);
        }
        if (err?.message === "REQUEST_CANCELLED") {
          return { errors: err?.message, response: null };
        }
        return errorFunction(err?.response?.data);
      });
  },


  /**
   * @memberof API
   * @method getParams 
   * @description function for generating the query parameters for the API call
   * @param {string} queryParams query string
   * @returns {string} returns the query string
   */
  getParams(queryParams = "") {
    return queryParams;
  },

  /**
   * @memberof API
   * @method get
   * @description function for making the get API request
   * @param {object} param0 object containing API path object, unique identifier(key), query parameters and options that are specific to the request
   * @returns {object} returns the API response object
   */
  async get({ path, key, queryParams, options = {} }) {
    const pathObj = typeof (path) === 'string' ? path : path?.path;
    let getData = {
      method: 'GET',
    };
    if (path?.baseURL) {
      getData = {
        ...getData,
        baseURL: path?.baseURL
      }
    }
    return await this.makeRequest(pathObj, key, getData, options);
  },

  /**
 * @memberof API
 * @method post
 * @description function for making the post API request
 * @param {object} param0 object containing API path object, unique identifier(key), payload of the request(body) and options that are specific to the request
 * @returns {object} returns the API response object
 */
  async post({ path, key, body, options = {} }) {
    const pathObj = typeof (path) === 'string' ? path : path?.path;
    let postData = {
      method: 'POST',
      data: body,
      params: this.getParams(),
      paramsSerializer: (params) => {
        return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
      },
    };
    if (path?.baseURL) {
      postData = {
        ...postData,
        baseURL: path?.baseURL
      }
    }
    return await this.makeRequest(pathObj, key, postData, options);
  },

  /**
 * @memberof API
 * @method put
 * @description function for making the put API request
 * @param {object} param0 object containing API path object, unique identifier(key),payload of the request(body) and options that are specific to the request
 * @returns {object} returns the API response object
 */
  async put({ path, key, body, options = {} }) {
    const putData = {
      method: 'PUT',
      data: body,
      params: this.getParams(),
      paramsSerializer: (params) => {
        return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
      },
    };
    return await this.makeRequest(path, key, putData, options);
  },

  /**
 * @memberof API
 * @method patch
 * @description function for making the patch API request
 * @param {object} param0 object containing API path object, unique identifier(key), payload of the request(body) and options that are specific to the request
 * @returns {object} returns the API response object
 */
  async patch({ path, key, body, options = {} }) {
    const patchData = {
      method: 'PATCH',
      data: body,
      params: this.getParams(),
      paramsSerializer: (params) => {
        return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
      },
    };
    return await this.makeRequest(path, key, patchData, options);
  },

  /**
 * @memberof API
 * @method delete
 * @description function for making the get API request
 * @param {object} param0 object containing API path object, unique identifier(key), payload of the request(body) and options that are specific to the request
 * @returns {object} returns the API response object
 */
  async delete({ path, key, body, options = {} }) {
    const deleteData = {
      method: 'DELETE',
      params: this.getParams(),
      paramsSerializer: (params) => {
        return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
      },
    };
    return await this.makeRequest(path, key, deleteData, options);
  },

  config,
};



// exponential retry function
/**
 * @function retryUserProfileInfo
 * @description function for performing retry of api with exponential delay
 * @param {Function} apiFunction function for performing API call
 * @param {number} maxRetries number representing maximum retries for the call 
 * @returns {Object} returns either the successful respone of the call or the Maximum retries error
 */
export const retryUserProfileInfo = async (apiFunction = () => { }, maxRetries = 5) => {
  let retries = 0;

  const performRetry = async () => {
    if (retries < maxRetries) {
      // add exponential deplay
      const delay = Math.pow(2, retries) * 1000;
      // Wait for the calculated delay before retrying
      await new Promise((resolve) => setTimeout(resolve, delay));
      retries++;
      return makeRequest();
    } else {
      throw new Error('Maximum retries reached');

    }
  }

  const makeRequest = async () => {
    try {
      const response = await apiFunction();
      if (response?.errors?.status_code === 404) {
        try {
          return await performRetry();
        } catch (error) {
          throw error;
        }
      }
      return response;
    } catch (err) {
      try {
        return await performRetry();
      } catch (error) {
        throw error;
      }
    }
  }
  return makeRequest();
}