import { data } from '../../models';
import { dataTypes } from '../types/data.types';


const INITIAL_STATE = {
    ...data
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case dataTypes?.MEETING_PLACES:
            return {
                ...state,
                meetingPlaces: action.payload
            }
        case dataTypes?.COUNTRIES:
            return {
                ...state,
                countries: action.payload
            }
        case dataTypes?.PHONE_PREFIXES:
            return {
                ...state,
                phonePrefixes: action.payload
            }
        default: return state;
    }
};
export default reducer;