/***
 *
 * Controller class for user.
 * @file FormErrorDisplay.js
 * @description FormErrorDisplay component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
// import styles from './FormErrorDisplay.module.scss';
import Error from '../../assets/svg/error.svg';
import { Grid, Typography } from '@mui/material';
import ImageWrapper from '../ImageWrapper';

const FormErrorDisplay = ({ children, containerProps = {} }) => {

  return (
    <Grid container columnSpacing={0.5} flexWrap={'nowrap'} alignItems={'center'} {...containerProps}>
      <Grid item height={'16px'}>
        <ImageWrapper src={Error} width={'16px'} alt="Error" />
      </Grid>
      <Grid item>
        <Typography variant='body1' sx={{ color: '#CC0511' }}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  )
};

FormErrorDisplay.propTypes = {};

FormErrorDisplay.defaultProps = {};

export default FormErrorDisplay;
