export const navigationTypes = {
    LOGIN: 2000,
    SELECTMEETINGPLACE: 1000,
    GENERALBOOKINGPAGE: 3000,
    RESOURCES: 4000,
    MEETINGROOMS: 5000,
    MEETINGROOMDETAILS: 5001,
    MEETINGROOMCONFIRM: 5002,
    MEETINGROOMBOOKINGSTATUS: 5003,
    RESOURCEDETAILS: 5004,
    RESOURCECONFIRM: 5005,
    RESOURCEBOOKINGSTATUS: 5006,
    GLOBALMANAGEBOOKING: 6000,
    EDITBOOKING: 7000,
    GLOBALEDITBOOKING: 7001,
    FINDBOOKINGS: 10000,
    PERSONALAREAPAGE: 40000,
    YOURBOOKINGS: 41000,
    BOOKINGDETAILS: 50000
}