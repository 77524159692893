/***
 *
 * Controller class for user.
 * @file LogInPage.js
 * @description LogInPage component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Typography, Grid, Paper } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import StyledButton from '../../components/StyledButton';
import StyledTextField from '../../components/StyledTextField';
import { API_URL_VALIDATE } from '../../config';
import { Navigate, SaveSession, history, RemoveSession, GetSession } from '../../helpers';
import { navigationTypes } from '../../redux/types';
import { API } from '../../services';
// import PropTypes from 'prop-types';
import './LogInPage.scss';
import FormErrorDisplay from '../../components/FormErrorDisplay';

export const LogInPage = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    RemoveSession('isAuthUser');
    RemoveSession("currentPage");
    RemoveSession("meetingPlace");
    RemoveSession("site");
  }, [])

  useEffect(() => {

  }, [error]);

  const onSubmit = (event) => {
    event.preventDefault();
    //Call for authentication token:
    API.post({
      path: {
        path: API_URL_VALIDATE,
        baseURL: ""
      }, key: 'USER-Validation', body: { username: id, passKey: password }
    }).then(resp => {
      if (resp?.status) {
        //On successful response, Navigate
        SaveSession("AuthToken", resp?.data?.validatedString);
        SaveSession("isAuthUser", JSON.stringify({ auth: true }));
        const redirectTo = GetSession("redirectTo") || null;
        if (redirectTo) {
          RemoveSession("redirectTo");
          history.replace(redirectTo);

          window?.location?.reload();
        } else {
          Navigate(navigationTypes.SELECTMEETINGPLACE, { dispatch, history })
        }
      }
      else {
        throw resp?.error;
      }
    }).catch(e => {
      console.log(e);
      setError(true)
    })
  }

  const containerSx = {
    display: 'flex',
    flexDirection: 'column',
    px: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    my: 'auto'
  }


  const paperSx = {
    boxShadow: '0px 0px 10px rgba(118, 117, 113, 0.3)',
    borderRadius: '20px',
    backgroundColor: '#eae3da',
    position: 'absolute',
    display: 'flex',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <Grid container sx={{ height: '100vh', xs: 12, md: 6 }}>
      <Paper variant={'primary'} sx={{ ...paperSx, width: { md: 610, sm: 400, xs: 300 }, minHeight: { md: 578, xs: 600 } }}>
        <Grid container sx={containerSx} direction={'column'} alignItems='center' justifyContent={'center'} component={'form'}>
          <Grid item mb={2}>
            <Typography align='center' variant='subtitle1'>User Id:</Typography>
            <StyledTextField
              autoComplete="username"
              value={id}
              variant="outlined"
              size="small"
              onChange={(e) => { setId(e.target.value) }}
            />
          </Grid>
          <Grid item mb={2}>
            <Typography align='center' variant='subtitle1'>Password:</Typography>
            <StyledTextField
              type="password"
              autoComplete="current-password"
              value={password}
              variant="outlined"
              size="small"
              onChange={(e) => { setPassword(e.target.value) }}
            />
          </Grid>
          <Grid item mb={2}>
            <StyledButton variant={'primary'} onClick={onSubmit} type='submit'>
              SUBMIT
            </StyledButton>
          </Grid>
          {error && <Grid item>
            <FormErrorDisplay>
              Incorrect Credentials
            </FormErrorDisplay>
          </Grid>
          }
        </Grid>
      </Paper >
    </Grid >
  )
};

LogInPage.propTypes = {};

LogInPage.defaultProps = {};

export default LogInPage;
