/***
 *
 * Controller class for user.
 * @file Spinner.js
 * @description Spinner component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import './Spinner.scss';
import loader from '../../assets/loader/spinner.png';
// import primaryLoader from '../../assets/images/primaryLoader.jpg';
import { useTranslation } from 'react-i18next';
import ImageWrapper from '../ImageWrapper';
import { Typography, useMediaQuery, useTheme, Box } from '@mui/material';
const Spinner = ({ style = {} }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const spinnerBox = {
    alignItems: 'center',
    width: matches ? '416px' : '261px',
    height: matches ? '232px' : '180px',
    backgroundColor: '#fff',
    borderRadius: '20px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    rowGap: '24px',
    transform: 'translate(-50%, -50%)',
    paddingBlock: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden'
  }

  const spinnerSx = {
    width: matches ? '100px' : '65px',
    animation: 'spinner 800ms linear infinite',
    '@keyframes spinner': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(360deg)' }
    }
  }


  return (
    <Box sx={style} className='spinner-container spinner-box' data-testid="Spinner" >
      <Box sx={spinnerBox} >
        <Box sx={{ height: 'auto' }}>
          <ImageWrapper style={spinnerSx} src={loader} alt="spinner" />
        </Box>
        <Typography component={'block'} variant={matches ? 'h3' : 'h4'} textAlign={'center'} >{t('S_JUST_A_MOMENT')}</Typography>
      </Box>
    </Box >
  )
};

Spinner.propTypes = {};

Spinner.defaultProps = {};

export default Spinner;