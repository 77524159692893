import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-xhr-backend';
// import Backend from 'i18next-locize-backend';
// import { DateTime } from 'luxon';

// Languages
// You can import muliple language here
import en from './locales/en.json';
import sv from './locales/sv.json';
import it from './locales/it.json';
import es from './locales/es.json';
import pl from './locales/pl.json';
import pt from './locales/pt.json';
import sk from './locales/sk.json';
import fr from './locales/fr.json';
import cs from './locales/cs.json';
import { GetSession, SaveSession } from "../helpers";
import { appDefaults } from "../config";

// The translations
// Add multiple translations

export const defaultLanguage = appDefaults?.language;




let lang = defaultLanguage;

const sessionLanguage = GetSession("selectedLanguage") || defaultLanguage;
const sessionCountry = GetSession("country") || JSON.stringify(appDefaults?.country);

if (sessionLanguage) {
    lang = sessionLanguage;
    SaveSession("selectedLanguage", lang);
}

if (sessionCountry && JSON.parse(sessionCountry)) {
    lang = sessionLanguage + "-" + JSON.parse(sessionCountry).code;
}

// console.log("path name ---", lang)

const resources = {
    en: { translation: en },
    sv: { translation: sv },
    it: { translation: it },
    es: { translation: es },
    pl: { translation: pl },
    pt: { translation: pt },
    sk: { translation: sk },
    fr: { translation: fr },
    cs: { translation: cs },
}

i18n
    .use(Backend)
    .use(initReactI18next)  // passes the i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        debug: true,
        lng: lang, // this will set default language and overwrite languagedetector
        defaultLocale: defaultLanguage,
        fallbackLng: [lang],   // use en if deteceted lng is not available
        // interpolation: {
        //     escapeValue: false, // react already safes from xss
        // },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            // format: (value, format, lng) => {
            //     if (value instanceof Date) {
            //         return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
            //     }
            //     return value;
            // }
        },
        detection: {
            order: ['htmlTag', 'localStorage', 'path', 'subdomain'],
        }
    });
// Use this for displaying options to the user on UI. This way all the language related config stays at one place.
// export const lang = [
//     { value: 'en', name: 'English' }
// ];
export default i18n;