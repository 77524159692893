import { userTypes, sessionTypes, navigationTypes } from '../types';
import { API, createICIMRedirectUrl, getCookie, getUserDetails, handleInternationalPhoneNumber, retryUserProfileInfo, search, searchUserByIcimId, setAlternativeEmail, setCmdId, setCookie, setIcimId, syncIcimAndPlanyoUserData, userService } from '../../services';
import { alertActions } from './alert.actions';
import { GetLocal, GetSession, Navigate, RemoveSession, SaveLocal, SaveSession } from '../../helpers';
import { setCountry, setMeetingPlace } from './app.actions';
import { DISABLE_API_LOADER, backendURL, icimUrlComponent } from '../../config';
import jwt_decode from 'jwt-decode'
import { policyTypes } from '../../constants';

function success(type, data = null) {
    let obj = { type: type, payload: {} };
    for (const d in data) {
        obj.payload[d] = data[d];
    }
    return obj;
}
function failure(type, error = null, id = null) {
    if (id) {
        return { type: type, id, error }
    } else {
        return { type: type, error }
    }
}

export const loginWithCountry = (country, meetingPlace, goto = null) => {
    return dispatch => {
        // dispatch(request(sessionTypes["REQUEST"], { meetingPlace }));
        userService.loginWithCountry(country, meetingPlace)
            .then(
                app => {
                    // dispatch(setAuthentication(app?.authentication));
                    dispatch(setMeetingPlace(app?.meetingPlace));
                    dispatch(setCountry(app?.country));
                },
                error => {
                    dispatch(failure(sessionTypes["FAILURE"], error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
}

export const loginWithSite = (site, goto = null) => {
    return dispatch => {

        userService.loginWithSite(site)
            .then(
                app => {
                    for (const d in app) {
                        dispatch(success(d, app[d]));
                    }
                    if (goto) {
                        Navigate(goto);

                    }
                },
            );
    };
}

export const setAuthentication = (auth) => {
    return {
        type: userTypes.AUTHENTICATION,
        payload: auth,
    };
}

/**
 * @function getUserToken
 * @description function for storing the ICIM user token. This function fires the user token API and on successful response save the token in cookie
 * @param {*} param0 object that contains:
 *                      - dispatch method for updating the redux store
 */
export const getUserToken = async ({ dispatch = () => { } }) => {
    const userCode = JSON.parse(GetLocal("userCode") || '{}')?.code;
    const redirectRoute = JSON.parse(GetLocal("redirectRoute"));
    const icimActionError = GetSession("icimActionError") || null;
    let policy = "";
    if (icimActionError) {
        policy = redirectRoute?.fallbackPolicy || policyTypes?.LOGIN_SIGNUP;
        redirectRoute["policyType"] = redirectRoute?.fallbackPolicy;
        SaveLocal("redirectRoute", JSON?.stringify(redirectRoute));

    } else {
        policy = redirectRoute?.policyType || policyTypes?.LOGIN_SIGNUP;
    }
    const grantType = "authorization_code";
    !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
    await API.get({
        path: {
            path: `${process.env.REACT_APP_ICIM_TENANT_ID}/oauth2/v2.0/token?p=${policy}&grant_type=${grantType}&code=${userCode}&client_secret=${process.env.REACT_APP_ICIM_CLIENT_SECRET}`,
            baseURL: icimUrlComponent
        },
        key: "ICIM-API-User-Token"
    }).then(response => {
        // handling the following error: The provided grant has not been issued for this endpoint. Actual Value : {0} and Expected Value : {1}
        if (response?.errors?.error === "invalid_grant" && response?.errors?.error_description?.includes('AADB2C90088')) {
            const text = response?.errors?.error_description;
            const expectedValuePattern = /Expected Value : ([^\r\n]+)/;
            const expectedValueMatch = text.match(expectedValuePattern);
            const expectedValue = expectedValueMatch ? expectedValueMatch[1] : null;
            redirectRoute["policyType"] = expectedValue?.toUpperCase();
            redirectRoute["fallbackPolicy"] = expectedValue?.toUpperCase();
            SaveLocal("redirectRoute", JSON.stringify(redirectRoute));
            window?.location?.reload();
        }
        let resp = response?.data || null;
        if (resp) {
            let userToken = resp?.id_token;
            let tokenExpiry = resp?.id_token_expires_in;
            setCookie("userToken", userToken, tokenExpiry);
            let redirectRoute = JSON.parse(GetLocal("redirectRoute")) || null;
            if (redirectRoute) {
                redirectRoute["tokenExpire"] = false;
                SaveLocal("redirectRoute", JSON.stringify(redirectRoute));
            }
        }
        if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, 200); }
    }).catch(error => {
        if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, 200); }
        console.log(error);
    })
}

/**
 * @function getUserProfileInfo
 * @description function for fetching the ICIM user details. 
 *                  - This function first decode the ICIM user token to extract the user email
 *                  - Then fire the userDetails API based on the email
 *                  - The successful response is stored in redux and user is marked as sign-in or sign-up
 * @param {*} param0 object that contains:
 *                      - dispatch method for updating the redux store
 *                      - userAction for defining the type of user
 * @returns {void}
 */
export const getUserProfileInfo = async ({ dispatch = () => { }, userAction = 'signIn' }) => {

    try {
        !DISABLE_API_LOADER && dispatch({ type: "apiLoader", payload: true });
        // check if cookie exits
        let token = getCookie('userToken') || null;
        if (!token) {
            await getUserToken({ dispatch }).then(() => {
                token = getCookie('userToken');
            })
        }
        let decodedToken = jwt_decode(token) || null;
        const email = decodedToken?.email;
        if (email) {
            let phoneNumber = {
                phonePrefix: '',
                number: ''
            };
            if (decodedToken?.phoneNumber) {
                phoneNumber = handleInternationalPhoneNumber(decodedToken?.phoneNumber);
            }
            const userPersonalDetails = {
                name: decodedToken?.given_name,
                surname: decodedToken?.family_name,
                email: decodedToken?.email,
                phone: phoneNumber?.number,
                phonePrefix: phoneNumber?.phonePrefix,
                userId: decodedToken?.sub,
                cmdId: decodedToken?.globalUid,
                userType: userAction,
                magicLink: JSON.parse(GetLocal("userCode") || '{}')?.magicLink
            }
            dispatch({ type: userTypes?.LOGIN_OR_SIGNUP, payload: userPersonalDetails });
            dispatch({ type: userTypes?.AUTHENTICATION, payload: true });
            SaveLocal("authentication", JSON.stringify({ loggedIn: true }));
            RemoveSession("icimActionError");
            dispatch({ type: sessionTypes?.ICIM_ACTION_ERROR, payload: "" })
        }
        // const email = decodedToken?.email;
        // if (email) {
        //     const response = await retryUserProfileInfo(() => {
        //         return API.get({
        //             path: {
        //                 path: `user-managment/v1/users/byemail/${email}`,
        //                 baseURL: `${backendURL}/icim/`
        //             },
        //             key: "ICIM-API-User-Details",
        //         })
        //     }, 5);
        //     const userDetailsObj = response?.data?.results || null;
        //     if (userDetailsObj) {
        //         let phoneNumber = {
        //             phonePrefix: '',
        //             number: ''
        //         };
        //         if (userDetailsObj?.phoneNumber) {
        //             phoneNumber = handleInternationalPhoneNumber(userDetailsObj?.phoneNumber);
        //         }
        //         console.log("user details , ", userDetailsObj)
        //         const userPersonalDetails = {
        //             name: userDetailsObj?.first_name,
        //             surname: userDetailsObj?.last_name,
        //             email: userDetailsObj?.email,
        //             phone: phoneNumber?.number,
        //             phonePrefix: phoneNumber?.phonePrefix,
        //             userId: userDetailsObj?.object_id,
        //             userType: userAction,
        //             magicLink: JSON.parse(GetLocal("userCode") || '{}')?.magicLink
        //         }
        //         dispatch({ type: userTypes?.LOGIN_OR_SIGNUP, payload: userPersonalDetails });
        //         dispatch({ type: userTypes?.AUTHENTICATION, payload: true });
        //         SaveLocal("authentication", JSON.stringify({ loggedIn: true }));
        //         RemoveSession("icimActionError");
        //         dispatch({ type: sessionTypes?.ICIM_ACTION_ERROR, payload: "" })
        //     } else {
        //         dispatch({ type: "funLoader", payload: false });
        //     }
        //     if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, 200); }
        // }
    } catch (error) {
        if (dispatch) { setTimeout(() => { dispatch({ type: "apiLoader", payload: false }); }, 200); }
        console.log(error);
    }
}

/**
 * @function syncEditProfileInfo
 * @description wrapper function for executing the modify_user data and handling the error case where we need to set the alternate email id
 * @param {object} param0 object containting user planyoId, dispatch funtion for updating redux, and updated user details object fetched from ICIM 
 * @returns {void}
 */
const syncEditProfileInfo = ({ planyoUserId, dispatch, userDetails }) => {
    const redirectRoute = JSON.parse(GetLocal("redirectRoute")) || null;
    if (redirectRoute?.policyType === policyTypes?.EDIT_PROFILE && planyoUserId && !redirectRoute?.dataSynced) {
        syncIcimAndPlanyoUserData(userDetails, planyoUserId, "user_id", false, dispatch)
            .then((resp) => {
                if (resp?.errors) {
                    setAlternativeEmail({ emailId: userDetails.email, planyoUserId }).then(() => {
                        // syncing the rest of the userDetails in case of failed updation
                        userDetails['email'] = '';
                        syncIcimAndPlanyoUserData(userDetails, planyoUserId, "user_id", false, dispatch)
                    }).catch(err => console.log(err))
                }
            }).catch(err => {
                console.log(err)
            });
        redirectRoute["dataSynced"] = "true";
        SaveLocal("redirectRoute", JSON.stringify(redirectRoute));
    }
}

/**
 * @function mapIcimIdtoPlanyoId
 * @description Fetches planyo user ID against an ICIM user ID, and maps them together by pushing the ICIM ID in the user's planyo details.
 * @param {object} userDetails object containing ICIM user information e.g -> name, surname, email etc.
 * @returns {function} Anonymous function that performs the specified action
 */
export const mapIcimIdtoPlanyoId = (userDetails) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "funLoader", payload: true });
            getUserDetails({ email: userDetails?.email }, dispatch)
                .then(resp1 => {
                    if (resp1?.data) {
                        if (!resp1.data?.properties?.icim_id || !resp1.data?.properties?.cmd_id || resp1.data?.properties?.icim_id === userDetails?.userId) {
                            dispatch({ type: userTypes?.SET_PLANYO_USER_ID, payload: resp1?.data?.id });
                            syncEditProfileInfo({ planyoUserId: resp1?.data?.id, dispatch, userDetails });
                        }
                        if (!resp1.data?.properties?.cmd_id && !resp1.data?.properties?.icim_id) {
                            syncIcimAndPlanyoUserData(userDetails, resp1?.data?.id, "user_id", true, dispatch)
                                .then((resp) => {
                                    if (resp?.errors) {
                                        console.log(resp?.errors)
                                    }
                                }).catch(err => {
                                    console.log(err)
                                });
                        } 
                        else if (!resp1.data?.properties?.cmd_id) {
                            setCmdId(userDetails?.cmdId, resp1?.data?.id, dispatch)
                                .then(() => {
                                }).catch(err => {
                                    console.log(err);
                                });
                        } else if (!resp1.data?.properties?.icim_id) {
                            // If cmdId is already set, call setIcimId immediately
                            setIcimId(userDetails?.userId, resp1?.data?.id, dispatch)
                                .then(() => {
                                }).catch(err => {
                                    console.log(err);
                                });
                        }
                        if (resp1.data?.properties?.icim_id && (resp1.data?.properties?.icim_id !== userDetails?.userId)) {
                            // update icim and cmd id for the existing planyo record
                            syncIcimAndPlanyoUserData(userDetails, resp1?.data?.id, "user_id", true, dispatch)
                                .then((resp) => {
                                    if (resp?.errors) {
                                        console.log(resp?.errors)
                                    }
                                }).catch(err => {
                                    console.log(err)
                                });
                        }
                    }
                    if (!resp1?.data && resp1?.errors && resp1?.errors !== "REQUEST_CANCELLED") {
                        dispatch({ type: "funLoader", payload: false });
                    }
                    dispatch({ type: "funLoader", payload: false });
                }).catch(err => {
                    console.log(err);
                    dispatch({ type: "funLoader", payload: false });
                })
        } catch (err) {
            console.log(err);
        }
    }
}
/**
 * @function handleIcimRedirection
 * @description function for saving current application status and redirecting the user to ICIM platform for login
 * @param {object} authentication object containing user logged in details 
 * @param {object} dispatch 
 * @param {object} history
 * @param {object} app object cotaining application status details
 * @param {object} location 
 * @returns {void}
 */
export const handleIcimRedirection = (authentication, dispatch, history, app, location) => {
    //If user is already logged in, don't redirect to ICIM login page
    if (authentication?.loggedIn) {
        Navigate(navigationTypes?.PERSONALAREAPAGE, { dispatch, history });
        return;
    }

    let redirectRoute = {
        routeId: navigationTypes?.PERSONALAREAPAGE,
        meetingPlace: app?.meetingPlace,
        country: app?.country,
        selectedLanguage: app?.selectedLanguage,
        site: app?.site,
        policyType: policyTypes?.LOGIN_SIGNUP
    }

    SaveLocal("redirectRoute", JSON.stringify(redirectRoute));

    //pushing current page to history
    history?.push(location?.pathname, { key: `${app?.page?.key}-${new Date()?.getTime()}`, page: JSON.stringify(app?.page) });

    // navigate the user to ICIM
    const ICIMUrl = createICIMRedirectUrl({ brandId: app?.meetingPlace?.brand_id, selectedLanguage: app?.selectedLanguage, policy: policyTypes?.LOGIN_SIGNUP });
    window?.location?.replace(ICIMUrl);
}
export const userLogoutRedirect = (policy, userToken) => {
    const userLogoutRedirectUrl = `${icimUrlComponent}/${process.env.REACT_APP_ICIM_TENANT_ID}/${policy}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}&id_token_hint=${userToken}`
    window?.location?.replace(userLogoutRedirectUrl);

}