import routes from "../routes/routes";
import language from '../data/language.json';
import meetingPlace from '../data/meetingPlace.json';
import countries from '../data/countries.json'
import { setLanguage } from "../redux/actions";
import { LOGINSCREEN, appDefaults } from "../config";
import { siteTypes } from "../constants";
import { GetSession } from "./";

export const isValidURL = (data) => {
    // debugger
    let resourceDetailsKeys = ['brd', 'brc', 'brp', 'rd', 'evd', 'mrd'];
    let url = data?.url?.toLowerCase();

    // console.log("404 -- 3 ", url);

    // url = url.replace(/\/+$/, '');

    // checking for multiple //
    if (url.match(/\/{2,}/g) !== null) return false;

    // exceptions
    if (
        url === '/'
        || url === '/admin-access-denied'
        || (LOGINSCREEN && url === '/login')
        || (url?.includes('/qr-action'))
    ) {
        return true;
    }
    // setting the default details for find booking form
    let urlDetails = {
        type: null,
        meetingPlace: null,
        language: '',
        country: '',
        key: 'bk'
    }
    let urlParameters = url?.split("/");
    // to remove the trailing empty string after the last '/'
    if (urlParameters[urlParameters.length - 1] === '') {
        urlParameters.pop();
    }
    if (url !== '/') {
        urlDetails = {
            ...urlDetails,
            language: urlParameters[1]?.split("-")[0],
            country: urlParameters[1]?.split("-")[1],
            meetingPlace: urlParameters[2],
            type: urlParameters?.length > 5 ? urlParameters[3] : null,
        }
    }
    if (urlParameters[3] === 'bd') {
        urlDetails.type = null;
    }
    if (urlParameters[2] !== 'bk') {
        if (urlDetails.type === urlParameters[3]) {
            urlDetails.key = urlParameters[4];
        } else {
            urlDetails.key = urlParameters[3];
        }
    }
    if (urlParameters[2] === 'bk' && urlParameters?.length !== 4) {
        return false;
    }
    // handling manage booking
    if (urlParameters[2] === 'mb') {
        // route length for manage booking is always 6
        if (urlParameters?.length !== 6) return false;
        urlDetails = {
            ...urlDetails,
            key: 'mb',
            type: null,
            meetingPlace: null
        }
    }

    const locationDetails = {
        isValidLanguageCountry: false,
        isValidMeetingPlace: false,
        isValidSiteType: false,
        isValidKey: false,
        isValidRoute: false,
    }

    // if (urlDetails.key !== 'bk') {
    if ((language?.find(currentItem => currentItem?.code?.toLowerCase() === urlDetails?.language))) {
        locationDetails.isValidLanguageCountry = true;
        /*
        -------------------------- in case of language code only on landing page ------------------------------------
        if(urlParameters?.length == 2 && locationDetails.isValidLanguageCountry){
            return true;
        }
        */

    } else {
        const cont = GetSession("country");
        data.i18n.changeLanguage(appDefaults?.language?.toLowerCase() + "-" + JSON?.parse(cont)?.code);
        data.dispatch(setLanguage(appDefaults?.language?.toLowerCase()));
    }
    if (countries?.find(currentItem => currentItem?.code?.toLowerCase() === urlDetails?.country)) {
        locationDetails.isValidLanguageCountry = locationDetails.isValidLanguageCountry && true
    } else {
        locationDetails.isValidLanguageCountry = false;
    }
    // } 
    if (urlParameters.length === 2 && locationDetails?.isValidLanguageCountry) {
        return true;
    }
    if (urlParameters.length === 3 && locationDetails?.isValidLanguageCountry && urlParameters[2]?.toLowerCase() === 'under-maintenance') {
        return true;
    }

    //  checking for site-type
    // missing country code check
    if (urlDetails.key === 'bk' || urlDetails.key === 'mb' || meetingPlace?.find(currentMeetingPlace => currentMeetingPlace.id === urlDetails.meetingPlace)) {
        locationDetails.isValidMeetingPlace = true;
    }
    if ((urlDetails.type === null) || (urlDetails.type === siteTypes.SERVICE || urlDetails.type === siteTypes.SPACE || urlDetails.type === siteTypes.EVENT || urlDetails.type === siteTypes.MEETINGROOM)) {
        locationDetails.isValidSiteType = true;
    }

    locationDetails.isValidKey = (routes?.find(currentItem => currentItem.key === urlDetails.key) || urlDetails.key === 'bk') ? true : false;

    let routeString = routes?.find(currentRoute => currentRoute.key === urlDetails.key)?.route?.split('/');
    if (routeString) {
        if (routeString.length > 2) {
            for (let i = 1; i < routeString.length; i++) {
                if (urlParameters?.indexOf(routeString[i]) !== -1) {
                    locationDetails.isValidRoute = true;
                } else {
                    locationDetails.isValidRoute = false;
                    break;
                }
            }
        } else if (routeString.length === 2 && urlParameters.indexOf(routeString[1]) !== -1) {
            locationDetails.isValidRoute = true;
        }
    }
    // for routes where last parameter is ID
    if (locationDetails.isValidKey) {
        let validResourceID = /^\d+$/.test(urlParameters[urlParameters.length - 1])
        if (resourceDetailsKeys?.find(item => item === urlDetails?.key) && !validResourceID) {
            return false;
        }
    }

    for (const key in locationDetails) {
        if (locationDetails[key] === true) {
            continue;
        }
        else {
            return false;
        }
    }
    return true;
}