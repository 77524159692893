/**
 * 
 * @name = Protected Routes
 * @description = The protected Route component is used 
 * to map all the authenticated routes
 * 
 * @author = Naveen Kumar
 * @since = 07 Oct, 2021
 * 
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes'; // Route list


const ProtectedRoutes = () => (
  <Switch>
    {routes.filter(r => !r.isPublicRoute).map(({ component: Component, path, exact, redirectTo }, ind) => (
      <Route path={`/${path}`} key={"path-" + ind} exact={exact}>
        {redirectTo ? <Component to={`/${redirectTo}`} /> : <Component />}
      </Route>
    ))}
  </Switch>
);


export default ProtectedRoutes;
