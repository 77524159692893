/*

    The old code for analytics have been removed as we no longer integrating Google Analytics directly in the code
    In order to retrieve the old code please switch to the following commit ID:
    Commit Id: b6a99252e1988d64bcbcdc590033b9f10f37915f

*/

import { dataLayerEvents } from '../constants/dataLayerEvents.constants';

/**
 * @function pushToDataLayer
 * @description function for pushing event data to the datalayer object of GTM. To ensure maximum flexibility, 
 *              portability, and ease of implementation, Google Tag Manager functions best when deployed alongside a data layer.
 * @param {object} dataLayerObject following are the keys of this object:
 *                                 event: virtual_page_view | booking
 *                                 market: language-countryCode
 *                                 meetingPlace: selected meeting place
 *                                 meetingPlaceId: id of the selected meeting place
 *                                 pagePath: URL of the current page
 *                                 pageTitle: same as title of the website
 *                                 date: in case of booking event, it represents the date when the resouce was booked
 *                                 type: Service | Event | Space | Meeting-Rooms
 *                                 ticket: Number of tickets booked in case of events 
 * @returns {void}
 */
export const pushToDataLayer = (dataLayerObject = {}) => {
    // find the last pushed virtual page view event
    let lastVirtualPageViewEntry = {};
    for (const item of window?.dataLayer?.slice()?.reverse()) {
        if (item?.event === dataLayerEvents?.VIRTUAL_PAGE_VIEW) {
            lastVirtualPageViewEntry = item;
            break;
        }
    }

    let eventInfo = {
        'market': dataLayerObject?.market,
        'meeting_place': dataLayerObject?.meetingPlace,
        'meeting_place_id': dataLayerObject?.meetingPlaceId
    }
    // in order to avoid the duplicate entry check the last pushed virtual_page_view event and check its path
    if (dataLayerObject?.eventName === dataLayerEvents?.VIRTUAL_PAGE_VIEW && lastVirtualPageViewEntry?.page_path !== dataLayerObject?.pagePath) {
        eventInfo = {
            'event': dataLayerEvents?.VIRTUAL_PAGE_VIEW,
            ...eventInfo,
            'page_path': dataLayerObject?.pagePath,
            'page_title': dataLayerObject?.title
        }
        window?.dataLayer?.push(eventInfo);
        // console.log("dataLayer array ", window?.dataLayer);
    } else if (dataLayerObject?.eventName === 'booking') {
        eventInfo = {
            'event': dataLayerEvents?.BOOKING,
            ...eventInfo,
            'date': dataLayerObject?.date,
            'type': dataLayerObject?.type,
            'ticket_number': dataLayerObject?.ticketNumber
        }
        window?.dataLayer?.push(eventInfo);
        // console.log("dataLayer array ", window?.dataLayer);
    }
}