import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import { appDefaults } from '../config';

const loggerMiddleware = createLogger();

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
let middleware = [thunkMiddleware];

if(appDefaults?.environment !== 'prod'){
    middleware = [...middleware, loggerMiddleware];
}

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(
        ...middleware,
    ))
);