import { Cookies } from 'react-cookie';
import { isServer } from './server.helper';

// const localStorage = require('localstorage');

const cookies = new Cookies();

// export function GetLocal(key=null) {
//     if(typeof window !== 'undefined') { 
//         if(key) { return localStorage.getItem(key); }
//         else { return localStorage; }
//     } else {
//         if(key) { return JSON.stringify(localStorage.getItem(key)); }
//         else { return JSON.stringify(localStorage); }
//     }    
// }

// export function SaveLocal(key, value) {
//     if(typeof window !== 'undefined') { 
//         localStorage.setItem(key, value);
//     } else {
//         localStorage.setItem(key, value);
//     }     
// }

// export function RemoveLocal(key) {
//     if(typeof window !== 'undefined') { 
//         localStorage.removeItem(key);
//     } else {
//         localStorage.removeItem(key);
//     } 
// }


export function SaveLocal(key, value) {
    if (!isServer) {
        localStorage.setItem(key, value);
    } else {
        cookies.set(key, value);
    }
}

export function GetLocal(key = null) {
    if (!isServer) {
        if (key) { return localStorage.getItem(key); }
        else { return localStorage; }
    } else {
        cookies.get(key);
    }
}

export function RemoveLocal(key) {
    if (!isServer) {
        localStorage?.removeItem(key);
    } else {
        cookies?.remove(key);
    }
}