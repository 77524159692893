/***
 *
 * Controller class for user.
 * @file SkipToContent.js
 * @description Skip To Content component
 * @author Sanchit Rustagi
 * @since 02 Feb 2023
 * 
 */

import {
  ButtonBase,
  styled,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import './SkipToContent.scss';
import { useTranslation } from 'react-i18next';


/**
 * @component
 * @description Component for Skip to content button
 */
const SkipToContentButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.subtitle1.fontWeight,
  lineHeight: theme.typography.subtitle1.lineHeight,
  fontFamily: theme.typography.subtitle1.fontFamily,
  textDecoration: 'underline',
  color: '#474747',
  borderRadius: '4px',
  '&:hover': {
    color: '#767571'
  },
  '&:focus-visible': {
    outlineOffset: '3px',
    outline: '1px solid #767571',
    color: '#474747'
  },
  '&:focus': {
    outlineOffset: '3px',
    outline: '1px solid #767571',
    color: '#474747'
  }
}))

/**
 * @component
 * @description Component for Skip to content functionality
 * @property {HTMLElement} mainRef
 */
const SkipToContent = ({ mainRef }) => {
  const { t } = useTranslation();

  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    if (isFocused) {
      if (containerRef?.current) {
        containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isFocused])

  /**
   * @function checkFocus
   * @param {SyntheticEvent} e 
   * @returns {void}
   * @description Checks if focus is on the Skip to content button
   */
  const checkFocus = (e) => {
    if (e.type === "focus") {
      setIsFocused(true);
    } else if (e.type === "blur") {
      setIsFocused(false)
    }
  };

  return (
    <div style={{
      width: '100%',
      backgroundColor: '#EAE3DA',
      padding: '8px 16px',
      position: `${isFocused ? 'static' : 'fixed'}`
    }} data-testid="SkipToContent"
      ref={containerRef}
      tabIndex={-1}
      id="skip-to-content-container">
      <SkipToContentButton
        onClick={() => {
          if (mainRef?.current) {
            mainRef?.current?.scrollIntoView({ behavior: 'smooth' });
            mainRef?.current?.focus();
          }
        }} onFocus={checkFocus} onBlur={checkFocus} >
        {t("S_SKIP_TO_MAIN_CONTENT")}
      </SkipToContentButton>
    </div>
  )
};

SkipToContent.propTypes = {
  // mainRef: PropTypes.shape({ current: PropTypes.instanceOf(HTMLElement) })
};

SkipToContent.defaultProps = {};

export default SkipToContent;
