import { filters } from "./filters.model"

const allResources = {
    "service": [],
    "space": [],
    "event": [],
    "room": []
}

export const resource = {
    filters: {
        ...filters
    },
    list: [],
    allResources: {
        ...allResources
    }
}