import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#ffdb00',
        },
        secondary: {
            main: '#eae3da',
        },
        error: {
            main: '#e30613',
        },
        warning: {
            main: '#ef9617',
        },
        info: {
            main: '#0058a3',
        },
        success: {
            main: '#00a366',
        },
        text: {
            primary: '#474747',
            disabled: '#767571',
            secondary: '#767571',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    '&::-webkit-scrollbar': {
                        width: '2px',
                        height: '2px',
                    },
                    '&::-webkit-scrollbar-button': {
                        width: '0px',
                        height: '0px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#767571',
                        border: '2px none #ffffff',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#767571'
                    },
                    '&::-webkit-scrollbar-thumb:active': {
                        background: '#767571'
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#eae3da',
                        border: '0px none #ffffff',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-track:hover': {
                        background: '#eae3da'
                    },
                    '&::-webkit-scrollbar-track:active': {
                        background: '#eae3da'
                    },
                    '&::-webkit-scrollbar-corner': {
                        background: 'transparent'
                    }
                }
            }
        },
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'primary' },
                    style: {
                        backgroundColor: '#ffffff'
                    }
                },
                {
                    props: { variant: 'alternate' },
                    style: {
                        backgroundColor: '#F7F4EF'
                    }
                }
            ]
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:focus': {
                        outlineOffset: '3px',
                        outline: '1px solid #767571',
                    },
                    "&.Mui-focusVisible": {
                        outlineOffset: '3px',
                        outline: '1px solid #767571',
                    }
                }
            },
            variants: [
                {
                    props: { variant: 'primary' },
                    style: {
                        minWidth: '150px',
                        borderRadius: '20px',
                        textTransform: 'none',
                        padding: '12px 15px',
                        backgroundColor: '#ffdb00',
                        '&:hover': {
                            backgroundColor: '#fdc300'
                        },
                        '&:active': {
                            backgroundColor: '#EF9617'
                        },
                    },
                },
                {
                    props: { variant: 'secondary' },
                    style: {
                        minWidth: '150px',
                        borderRadius: '20px',
                        textTransform: 'none',
                        padding: '12px 15px',
                        backgroundColor: '#eae3da',
                        '&:hover': {
                            backgroundColor: '#d7cfc6'
                        },
                        '&:active': {
                            backgroundColor: '#ADAAA3'
                        }
                    },
                },
                {
                    props: { variant: 'disabled' },
                    style: {
                        minWidth: '150px',
                        borderRadius: '20px',
                        color: '#adaaa3',
                        textTransform: 'none',
                        padding: '12px 15px',
                        backgroundColor: '#d7cfc6',
                        '&:hover': {
                            backgroundColor: '#d7cfc6'
                        },
                        '&:active': {
                            backgroundColor: '#d7cfc6'
                        }
                    },
                },
                {
                    props: { disabled: true },
                    style: {
                        minWidth: '150px',
                        borderRadius: '20px',
                        color: '#adaaa3',
                        textTransform: 'none',
                        padding: '12px 15px',
                        backgroundColor: '#d7cfc6',
                        '&:hover': {
                            backgroundColor: '#d7cfc6'
                        },
                        '&:active': {
                            backgroundColor: '#d7cfc6'
                        }
                    }
                }
            ]
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:focus': {
                        outlineOffset: '3px',
                        outline: '1px solid #767571',
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#474747',
                    borderRadius: '4px',
                    '&:hover': {
                        color: '#767571'
                    },
                    '&:active': {
                        color: '#333333'
                    },
                    '&:visited': {
                        color: '#0058A3'
                    },
                    '&:focus-visible': {
                        outlineOffset: '3px',
                        outline: '1px solid #767571',
                        color: '#474747'
                    }
                },
            }
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: '#474747'
                }
            }
        }
        // MuiCssBaseline: {
        //     styleOverrides: [`
        //     @font-face {
        //         font-family: 'Lively Headline';
        //         font-style: normal;
        //         font-display: swap;
        //         font-weight: 700;
        //         src: local('Lively Headline Bold'), local('LivelyHeadline-Bold'), url(${LivelyHeadlineBoldTTF}) format('truetype');
        //       }`,
        //     `@font-face {
        //         font-family: 'Noto IKEA Latin';
        //         font-style: normal;
        //         font-display: swap;
        //         font-weight: 400;
        //         src: local('Noto IKEA Latin'), local('NotoIKEALatin-Regular'), url(${NotoIKEALatinRegularTTF}) format('truetype');
        //     }`,
        //     `@font-face {
        //         font-family: 'Noto IKEA Latin';
        //         font-style: normal;
        //         font-display: swap;
        //         font-weight: 700;
        //         src: local('Noto IKEA Latin Bold'), local('NotoIKEALatin-Bold'), url(${NotoIKEALatinBoldTTF}) format('truetype');
        //     }`,
        //     `@font-face {
        //         font-family: 'Noto IKEA Latin';
        //         font-style: italic;
        //         font-display: swap;
        //         font-weight: 400;
        //         src: local('Noto IKEA Latin Italic'), local('NotoIKEALatin-Italic'), url(${NotoIKEALatinItalicTTF}) format('truetype');
        //     }`,
        //     `@font-face {
        //         font-family: 'Noto IKEA Latin';
        //         font-style: normal;
        //         font-display: swap;
        //         font-weight: 700;
        //         src: local('Noto IKEA Latin Bold Italic'), local('NotoIKEALatin-BoldItalic'), url(${NotoIKEALatinBoldItalicTTF}) format('truetype');
        //     }`
        //     ]
        // }
    },
    typography: {
        fontFamily: 'Noto IKEA Latin, Lively Headline',
        h1: {
            fontWeight: 700,
            fontSize: 40,
            lineHeight: 1.1,
            fontFamily: 'Lively Headline',
        },
        h2: {
            fontSize: 36,
            fontWeight: 700,
            lineHeight: 1,
            fontFamily: 'Lively Headline',
        },
        h3: {
            fontSize: 28,
            fontWeight: 700,
            lineHeight: 1.14,
            fontFamily: 'Lively Headline',
        },
        h4: {
            fontSize: 24,
            fontWeight: 700,
            lineHeight: 1.17,
            fontFamily: 'Lively Headline',
        },
        h5: {
            fontSize: 16,
            fontWeight: 700,
            lineHeight: 1.25,
            fontFamily: 'Lively Headline',
        },
        h6: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1.29,
            fontFamily: 'Lively Headline',
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1.62,
            fontFamily: 'Noto IKEA Latin'
        },
        subtitle2: {
            fontSize: 14,
            fontWeight: 400,
            fontFamily: 'Noto IKEA Latin'
        },
        body1: {
            fontSize: 12,
            fontFamily: 'Noto IKEA Latin'
        },
        body2: {
            lineHeight: 1.6,
            fontSize: 10,
            fontFamily: 'Noto IKEA Latin'
        },
        button: {
            fontSize: 12,
            fontWeight: 700,
            lineHeight: '18px',
            fontFamily: 'Noto IKEA Latin',
        },
    },
})