import { createBrowserHistory, createMemoryHistory } from 'history';
import { isServer } from './server.helper';

// export const history = createBrowserHistory();
export const history = isServer
  ? createMemoryHistory({
      initialEntries: ['/'],
    })
  : createBrowserHistory();

// export const history = createMemoryHistory({ initialEntries: ['/'] });

// export const history = createMemoryHistory();