/***
 *
 * Controller class for user.
 * @file FormFieldLabel.js
 * @description FormFieldLabel component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
// import styles from './FormFieldLabel.module.scss';
import { Typography } from '@mui/material';

const FormFieldLabel = ({ id, text }) => {

  return (
    <Typography variant='subtitle2' data-testid="FormFieldLabel" mb={0.5} id={id}>
      {text}
    </Typography>
  )
};

FormFieldLabel.propTypes = {};

FormFieldLabel.defaultProps = {};

export default FormFieldLabel;
