/***
 *
 * Controller class for user.
 * @file ProgressBarPopup.js
 * @description ProgressBarPopup component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBarPopup.scss';
import classNames from 'classnames';
import ProgressBarIcon from '../ProgressBarIcon/index';

const ProgressBarPopup = (props) => {

  const menuItemClick = () => {
    props.closePopup();
  }

  return (
    <div data-testid="ProgressBarPopup" className="pop_popup" style={{ display: (props.open ? 'contents' : 'none') }}>
      <div className={classNames("pop_popup_background", props.className)} onClick={() => { props.outsideClickClose === 'true' && menuItemClick() }}></div>
      <div className="pop_popup_body" >
        {
          !props.noClose &&
          <div className="pop_popup_title">
            <div className="pop_popup_close_icon" onClick={() => menuItemClick()}>
              <ProgressBarIcon>{'close'}</ProgressBarIcon>
            </div>
          </div>
        }
        <div>
          {props.children}
        </div>
      </div>
    </div>
  )
};

ProgressBarPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  text: PropTypes.string,
  closePopup: PropTypes.func,
  children: PropTypes.any,
  outsideClickClose: PropTypes.string,
  noClose: PropTypes.bool
};

ProgressBarPopup.defaultProps = {};

export default ProgressBarPopup;
