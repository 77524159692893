import { GetSession } from "../helpers";

export const app = {
    page: {},
    selectedLanguage: GetSession("selectedLanguage"),
    country: {},//JSON.parse(GetSession("country") || "{}"),
    meetingPlace: {},//JSON.parse(GetSession("meetingPlace") || "{}"),
    site: {},//JSON.parse(GetSession("site") || "{}"),
    alert: {
        title: "",
        message: ""
    },
    appLoader: false,
    pageLoader: false,
    compLoader: false,
    apiLoader: false,
    funLoader: false,
    error: "",
    exitBooking: false,
    icimActionError: "",
    portal: ""
}